<template>
  <v-row justify="center">
    <v-dialog
      :fullscreen="$vuetify.breakpoint.width < mobile_border"
      v-model="dialog"
      persistent
      max-width="700px">

      <v-progress-circular
          v-if="loading"
          :size="50"
          color="green"
          style="position: fixed; right: 50%; top: 50%; z-index: 2"
          indeterminate>
          <v-icon color="green">mdi-cupcake</v-icon>
      </v-progress-circular>

      <v-card color="main_background">
        <v-form ref="product" v-model="valid" validate-on="submit input">
          <v-card-text>
            <v-row>
              <v-col class="d-flex justify-end">
                <button
                  class="common-button create-product-exit-button_top"
                  @click="closeDialog(false)">
                  &#10006;
                </button>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex pt-0 pb-0 create-product" cols=12 xs=12 sm=6>
                <v-select
                  :items="categories" 
                  v-model="draft_productItem['product_type']" 
                  :label="text.categories + ' *'"
                  :rules="[rules.required]"
                ></v-select>
              </v-col>
              <v-col class="d-flex pt-0 pb-0 mt-0 create-product" cols=12 xs=12 sm=6>
                <v-text-field
                  :rules="[rules.required, rules.string, custom_rules.productNameUnique]"
                  v-model="draft_productItem.name"
                  :label="text.product.name + ' *'">
                </v-text-field>
              </v-col>
            </v-row>

            <v-row style="margin-top: 25px;">
              <v-col 
                v-for="(name, label) in this.componentName"
                cols="6 pt-0 pb-0"
                class="create-product"
                xs=6 sm=4
                :key="name + '_new_product'">
                <v-text-field
                    :label="name + ' *'"
                    :rules="[rules.required, rules.double]"
                    v-model="draft_productItem[label]"
                    @click="resetErrorMsg()"
                    suffix="%">
                </v-text-field>
              </v-col>
            </v-row>

            <v-row style="margin-top: 25px !important;">
              <v-col
                v-for="(name, label) in this.kalloriesMap"
                cols="6 pt-0 pb-0"
                class="create-product"
                xs=6 sm=4
                :key="name + '_new_product'">
                <v-text-field
                    :label="name"
                    :rules="[rules.doubleWithZero]"
                    v-model="draft_productItem[label]"
                    @click="resetErrorMsg()">
                </v-text-field>
              </v-col>
            </v-row>

            <v-row style="margin-top: 30px !important;">
              <v-col
                  v-for="(label, name) in this.stopProductsMap"
                  class="create_product_stops"
                  cols="6 pt-0 pb-0"
                  xs=6 sm=4
                  :key="name + '_stop_products'">
                  <v-checkbox
                      v-model="draft_productItem[label]"
                      :value="false"
                      :label="name"
                  ></v-checkbox>
              </v-col>
            </v-row>

          </v-card-text>
          <v-card-text :class="[err_msg_class, 'err-msg']">
              {{ this.text.product.wrong_component_sum }}
          </v-card-text>
          <v-card-actions class='py-5'>
            <v-spacer></v-spacer>
            <button
              :style="valid ? 'opacity: 1' : 'opacity: 0.5'"
              :disabled="!valid"
              class="common-button create-product-exit-button"
              @click="checkAndCloseDialog(false)">
              {{ text.save }}
            </button>

            <button
              style="margin-left: 15px"
              class="common-button create-product-exit-button"
              @click="closeDialog(false)">
              {{ text.cancel }}
            </button>
          </v-card-actions>
        </v-form>  
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { COMPONENT_NAME, ALERT_TYPE, STOP_PRODUCTS_MAP, KALLORIES, MOBILE_BORDER } from './define.js'
import all_text from './ru-text'
import all_rules from './rules'
export default {
  props: {
    productItem: {
        Object
    },
    dialog: {
        type: Boolean,
    },
    closeDialog: {
        type: Function
    },
    changeMode: {
        type: Boolean
    }
  },
  name: 'CreateUpdateProduct',
  data() {
    return {
      mobile_border: MOBILE_BORDER,
      componentName: COMPONENT_NAME,
      stopProductsMap: STOP_PRODUCTS_MAP,
      kalloriesMap: KALLORIES,

      valid: false,
      changed: this.changeMode ? false : true,
      draft_productItem: {},
      categories: [],
      err_msg_class: "err-msg-hide",

      loading: false,

      text: all_text,
      rules: all_rules, 
      custom_rules: {
        productNameUnique: value => {
          if (!value) {
            return all_text.need_value
          }
          let products = this.$store.getters.PRODUCTS != null ?
                          this.$store.getters.PRODUCTS : {};
          let temp = value.trim()
          let check = false
          if (this.changeMode && temp == this.productName.trim()) {
            check = true
          } else {
            check = Object.keys(products).every(productType => {
              return products[productType].every(info => {
                return info.name != temp
              })
            })
          }
          return !!check || all_text.not_unique_name
        }
      },
    }
  },
  created() {
    this.draft_productItem = JSON.parse(JSON.stringify(this.productItem))

    if (this.$store.getters.PRODUCT_TYPES == null) {
      this.$store.dispatch('GET_PRODUCT_TYPES').then((res) => {
          if (res.status == 200) {
              this.categories = res.data
          } else {
              this.$store.commit("RAISE_ALERT", 
                                  {'message': this.text.common_error_message,
                                    'status': ALERT_TYPE.ERROR})
          }
      }).catch(() => {
          this.$store.commit("RAISE_ALERT", 
                              {'message': this.text.common_error_message,
                                'status': ALERT_TYPE.ERROR})
      });
    } else {
        this.categories = this.$store.getters.PRODUCT_TYPES
    }
  },
  computed: {
    productName() {
      let name = null
      if (this.changeMode) {
        name = this.draft_productItem['name']
      }
      return name
    }
  },
  watch: {
    draft_productItem: {
      handler: function () {
        if (this.changeMode) {
          let changed = false;
          Object.entries(this.draft_productItem).forEach(([key, val]) => {
              if (this.productItem[key] != val) {
                changed = true;
              }
          })
          this.changed = changed
        }
      },
      deep: true
    }
  },
  methods: {
    resetErrorMsg() {
      if (this.err_msg_class != 'err-msg-hide'){
        this.err_msg_class = "err-msg-hide"
        this.valid = true
      }
    },
    validate() {
      this.valid = this.$refs.product.validate()

      let sum = 0;
      Object.entries(this.componentName).forEach(([name, ]) => {
          sum += parseFloat(this.draft_productItem[name])
      })
      if (Math.abs(sum - 100) > 1) {
          this.err_msg_class = 'err-msg-show'
          this.valid = false;
      }
    },
    checkAndCloseDialog() {
      this.validate()
      if (this.valid) {
        let method = this.changeMode ? 'UPDATE_PRODUCT' : 'ADD_PRODUCT'
        this.$store.dispatch(method, this.draft_productItem).then((res) => {
          if (res.status == 201 && method == 'ADD_PRODUCT' ||
              res.status == 200 && method == 'UPDATE_PRODUCT') {
              this.closeDialog(true, true, res.data)
              this.$store.commit("RAISE_ALERT",
                          {'message': this.text.product_created,
                            'status': ALERT_TYPE.SUCCESS})
          } else {
            this.$store.commit("RAISE_ALERT",
                                {'message': this.text.common_error_message,
                                  'status': ALERT_TYPE.ERROR})
            }
        }).catch((err) => {
          this.$store.commit("RAISE_ALERT", 
                              {'message': this.text.common_error_message,
                                'status': ALERT_TYPE.ERROR})
        });
      }
    }
  }
}
</script>
<style lang="scss">
@import './common.scss';

.err-msg {
  padding-left: 15px;
  margin-top: -10px;
  font-size: 12px
}

.err-msg-show {
  color: var(--v-red-base) !important;
}

.err-msg-hide {
  color: var(--v-main_background-base) !important;
}

.create-product-exit-button {
  /* margin-top: 10px; */
  width: 120px;
  height: 35px;
  font-size: 16px;
}

.create-product-exit-button_top {
  width: 60px;
  height: 30px;
  font-size: 12px;
}

.create-product>.theme--light.v-input, .create-product>.theme--light.v-input input, .create-product>.theme--light.v-input textarea {
  color: var(--v-green-base) !important;
}
.create-product .theme--light.v-label {
  color: var(--v-green-base) !important;
}

.create-product .theme--light.v-messages {
  color: var(--v-red-base) !important;
}

.create-product .v-text-field>.v-input__control>.v-input__slot:after, .v-text-field>.v-input__control>.v-input__slot:before {
  border-color: var(--v-green-base) !important;
}

.create-product .theme--light.v-text-field>.v-input__control>.v-input__slot:before {
  border-color: var(--v-green-base) !important;
}

.create-product .v-text-field .v-select__slot .v-icon {
  color: var(--v-green-base) !important;
}

.create-product .v-select__selection--comma {
  color: var(--v-green-base) !important;
}

.create_product_stops .v-input--checkbox .v-label {
  color: var(--v-green-base) !important;
}

.create_product_stops .v-input--checkbox .v-input--selection-controls__ripple {
  color: var(--v-green-base) !important;
}

.create_product_stops .v-input--checkbox .v-input--selection-controls__input .v-icon {
  color: var(--v-green-base) !important;
}

.create_product_stops .v-input--checkbox .v-input--selection-controls__input .primary--text {
  color: var(--v-green-base) !important;
  caret-color: var(--v-green-base) !important;
}
</style>
