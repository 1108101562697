<template>
  <v-container >
    <NewCleverIngredient
        v-if="addNewIngredient"
        :closeDialog="onCloseNewIngredientDialog"
        :dialog="addNewIngredient"
        :categories="categories"
        :allowded_component="component"
        :prohibited_products="prohibited_products"/>

    <v-card
      class="main_background"
      elevation="0"
      style="margin: 0px !important">
      <v-form :ref="'form' + allowded_component" v-model="form_valid">
        <v-card-text>
          <div style="color: var(--v-green-base); font-size: 14px;">
            {{ title }}
          </div>
          <span class="clever-ingredients-checkbox">
            <v-checkbox
                v-if="optional_ingeds_setup"
                color="green"
                v-model="setupIngredients"
                :label="text.calculator.choose_custom_ingredients"
                @change="updateSetupIngredients()">
            </v-checkbox>
          </span>
          <span class="clever-ingredients-checkbox">
            <v-checkbox
                v-if="optional_ingeds_setup"
                :disabled="!setupIngredients || ratio_info.length < 2"
                :style="!setupIngredients || ratio_info.length < 2 ? 'opacity: 50%' : ''"
                color="green"
                v-model="setupIngredientsProportion"
                :label="text.calculator.put_custom_propotion"
                @change="updateSetupIngredients()">
            </v-checkbox>
          </span>
          <v-expand-transition>
            <button
              v-if="setupIngredients"
              class="common-button add-ingred-calc-btn"
              @click="addNewIngredient = true">
                {{ text.calculator.add_ingred }}
            </button>
          </v-expand-transition>

          <v-expand-transition>
            <div v-if="ratio_info.length > 0" style="padding-top: 12px; padding-bottom: 12px">
              <div class="short-green-line"/>
              <template v-for="(item, i) in ratio_info">
                <div class="clevel-calc-custom-ingred-container" :key="i + item.name + 'list_item'">
                  <div style="justify-self: start; padding-left: 12px; color: var(--v-green-base)">
                    {{ item.name }}
                  </div>
                  <div v-if="setupIngredientsProportion && ratio_info.length > 1"
                       style="width: 100%">
                    <v-text-field
                        class="ma-0 pa-0"
                        v-model="ratio_info[i]['ratio']"
                        :rules="[rules.required]"
                        @click="resetErrorMsg()"
                        label="Процент ингредиента">
                    </v-text-field>
                  </div>
                  <div class="clevel-calc-custom-igred-btn">
                    <button @click="onDeleteIngredient(i)" style="display: grid">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <img
                            src="./icons/product_remove.svg"
                            style="cursor: pointer;  margin: 5px"
                            height="20px"
                            width="20px"
                            v-bind="attrs"
                            v-on="on" />
                        </template>
                        <span>{{ text.recipe.remove_product }}</span>
                      </v-tooltip>
                    </button>
                  </div>
                </div>
                <div class="short-green-line" :key="i + item.name + '_divider'"/>
              </template>
            </div>
          </v-expand-transition>

          <div :class="[err_msg_class, 'err-msg']">
            {{ err_msg }}
          </div>
                    <!-- <v-row class="mt-3" v-if="setOwnPortionValue">
                        <v-checkbox
                            color="secondary"
                            v-model="setupFullIngredientProportion"
                            label="Указать общее содержание компонентов">
                        </v-checkbox>
                    </v-row> -->
          <div v-if="setOwnPortionValue" class="main-proportion">
            <v-text-field
              v-model="proportion"
              :rules="[rules.double, rules.required]"
              :label="text.calculator.main_proportion">
            </v-text-field>
          </div>
        </v-card-text>
      </v-form>
    </v-card>

    <button
      class="common-button clever-calc-next-btn"
      :disabled="!form_valid || (!optional_ingeds_setup && ratio_info.length == 0)"
      :style="!form_valid || (!optional_ingeds_setup && ratio_info.length == 0) ? 'opacity: 80%' : '' "
      @click="customOnNext()">
        {{ text.next }}
    </button>
    <button
      class="common-button clever-calc-next-btn"
      @click="onPrev()">
        {{ text.back }}
    </button>
  </v-container>
</template>

<script>
import NewCleverIngredient from './NewCleverIngredient.vue'
import { COMPONENT_NAME } from './define.js'
import all_rules from './rules'
import all_text from './ru-text'
export default {
  props: {
    ratio_info: {
      Array
    },
    optional_ingeds_setup: {
      type: Boolean,
      default: true
    },
    setOwnPortionValue: {
      type: Function,
      default: undefined
    },
    categories: {
      Array
    },
    title: {
      String
    },
    component: {
      String
    },
    //   stop_products: {
    //       type: Array,
    //       default: []
    //   },
    allowded_component: {
      type: String,
      default: ''
    },
    onNext: {
      Function
    },
    onPrev: {
      Function
    }
  },
  name: 'CleverIngredientsList',
  components: {
    NewCleverIngredient,
  },
  data() {
    return {
      form_valid: this.optional_ingeds_setup,

      proportion: undefined,

      setupFullIngredientProportion: false,
      setupIngredients: false || !this.optional_ingeds_setup,
      setupIngredientsProportion: false || !this.optional_ingeds_setup,
      addNewIngredient: false,
      prohibited_products: [],

      components_name: COMPONENT_NAME,

      text: all_text,
      rules: all_rules,
      err_msg: all_text.product.wrong_component_sum,
      err_msg_class: 'err-msg-hide',
    }
  },
  created() {
    this.form_valid = this.optional_ingeds_setup
  },
  methods: {
    resetErrorMsg() {
      if (this.err_msg_class != 'err-msg-hide'){
        this.err_msg_class = "err-msg-hide"
        this.form_valid = true
      }
    },
    checkComponentSumValid() {
      let sum = 0;
      this.ratio_info.forEach((info,) => {
        sum += parseFloat(info['ratio'])
      })
      return Math.abs(sum - 100) <= 0
    },
    customOnNext() {
      if (this.setupIngredientsProportion && this.ratio_info.length > 1 &&
          !this.checkComponentSumValid()) {
        this.form_valid = false;
        this.err_msg_class = 'err-msg-show'
        return
      }
      // if (this.setOwnPortionValue) {
      //     this.setOwnPortionValue(this.proportion)
      // }
      this.onNext()
    },
    onDeleteIngredient(i) {
      this.prohibited_products.splice(i, 1)
      this.ratio_info.splice(i,1)
    },
    updateSetupIngredients() {
      if (!this.setupIngredients) {
        this.setupIngredientsProportion = false
        this.ratio_info.splice(0, this.ratio_info.length)
      }
      if (!this.setupIngredientsProportion) {
        this.ratio_info.forEach((info) => {
          info['ratio'] = undefined
        })
      }
      if (this.prohibited_products) {
        this.prohibited_products.splice(0, this.prohibited_products.length)
      }
    },
    onCloseNewIngredientDialog(save, productItem) {
      if (save) {
        this.ratio_info.push(Object.assign({}, productItem))
        this.prohibited_products.push(productItem.name)
      }
      this.addNewIngredient = false
    },
  }
}
</script>
<style>
/* .v-input--checkbox .v-label {
  font-size: 14px !important;
} */

.add-ingred-calc-btn {
  font-size: 14px !important;
  margin-top: 30px;
  margin-bottom: 10px;
  padding: 5px 18px;
  background-color: var(--v-green-base) !important;
}

.clevel-calc-custom-ingred-container {
  display: grid;
  grid-template-columns: 40% 40% 20%;
  justify-items: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.main-proportion {
  margin-top: 30px;
  margin-bottom: -20px;
  width: 50%;
}

.clevel-calc-custom-igred-btn {
  grid-column-start: 3;
}

.clever-ingredients-checkbox .v-input--checkbox .v-label {
  color: var(--v-green-base) !important;
}

.clever-ingredients-checkbox>.v-input--checkbox .v-input--selection-controls__ripple {
  color: var(--v-green-base) !important;
}

.clever-ingredients-checkbox>.v-input--checkbox .v-input--selection-controls__input .v-icon {
  color: var(--v-green-base) !important;
}

.clever-ingredients-checkbox>.v-input--checkbox .v-input--selection-controls__input .primary--text {
  color: var(--v-green-base) !important;
  caret-color: var(--v-green-base) !important;
}
</style>
