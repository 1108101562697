<template>
  <v-container>
        <!-- create new recipe only from calculators, but  -->
        <!-- feature of creating in Recipe page is left in the Recipe.vue -->
        <!-- and just comment here just in case , to activate feature change @click behavior -->
        <!-- @click="() => {this.$router.push('/recipe/new');}" -->
    
    <TitleOfLists
      :main_title="text.recipe.recipes"
      :updateSearch="updateSearch"
      :createNewTitle="text.recipe.create_new"
      :onCreateNew="() => {this.$router.push('/calculator');}"
      :applyFilters="applyFilters"
      :setCheckbox="setCheckbox"
      :updateSelectedStopProducts="updateSelectedStopProducts"
      :titleForOwners="text.recipe.only_my"/>

    <template v-if="!$store.getters.LOADING && recipes && recipes.length > 0">
      <span class="all-card-container" style="margin-top: 40px">
        <span v-for="(recipe, index) in recipes" :key="index + recipe.name" class="card-container card-container-size">
          <span class="products-card-content-container">
            <span class="card-text" style="justify-self: left;">{{ recipe.name }}</span>
            <img
              style="justify-self: right;"
              v-if="recipe.is_owner"
              src="./icons/user.svg" />
          </span>
          <span style="align-self: end;">
            <StopProducts :info="recipe" 
                          :smallMode='true'
                          style="padding-left: 0px !important;
                                  padding-right: 0px !important;
                                  padding-top: 20px !important;
                                  padding-bottom: 20px !important;"/>
            <div
              v-for="(name, label) in component_names"
              :key="label + recipe.name"
              class="products-card-content-container card-text" style="font-size: 15px !important;">
                <span style="justify-self: left;">{{ name }}</span> 
                <span style="justify-self: right; padding-right: 10px;">{{ recipe[label].toFixed(2) }}</span>
            </div>
          </span>

          <button
            :disabled="$store.getters.LOADING"
            class="card-product-button common-button"
            style="align-self: end;"
            @click="goToRecipe(recipe.id)">
            {{ text.show }}
          </button>
        </span>
      </span>
    </template>
    <template v-else-if="!$store.getters.LOADING && recipes && recipes.length == 0">
      <div class="products-not-found">{{ text.recipes_not_found }}</div>
    </template>
  </v-container>
</template>

<script>
import StopProducts from './StopProducts.vue'
import TitleOfLists from './TitleOfLists.vue'
import {ALERT_TYPE, STOP_PRODUCTS_MAP, COMPONENT_NAME, KALLORIES} from './define'
import all_text from './ru-text'
export default {
  name: 'Recipes',
  components: {
    StopProducts,
    TitleOfLists
  },
  data() {
    return {
        recipes: null,
        allRecipes: null,
        search: '',

        selectedStopProducts: [],
        myRecipesOnly: false,

        text: all_text,
        kalories: KALLORIES,
        component_names: COMPONENT_NAME
      }
    },
    created() {
        if (this.$store.getters.RECIPES == null) {
            this.$store.dispatch('GET_RECIPES').then((res) => {
                if (res.status == 200) {
                    this.allRecipes = this.$store.getters.RECIPES
                    this.recipes = this.$store.getters.RECIPES
                } else {
                    this.$store.commit("RAISE_ALERT", 
                                        {'message': this.common_error_message,
                                        'status': ALERT_TYPE.ERROR})
                }
            });
        } else {
            this.allRecipes = this.$store.getters.RECIPES
            this.recipes = this.$store.getters.RECIPES
        }
    },
    computed: {
        allStopProducts()
        {
            return Object.keys(STOP_PRODUCTS_MAP);
        }
    },
  methods: {
    updateSearch(new_search) {
      this.search = new_search
      this.applyFilters()
    },
    setCheckbox(myRecipesOnly) {
      this.myRecipesOnly = myRecipesOnly;
    },
    updateSelectedStopProducts(newSelectedStopProducts) {
      this.selectedStopProducts = newSelectedStopProducts;
    },
    goToRecipe(id) {
        this.$router.push('/recipe/' + id);
    },
    applyFilters() {
      let newRecipes = []
      let lowerSearch = this.search.toLowerCase()
      Object.entries(this.allRecipes).forEach(([,info]) => {
      let add = this.selectedStopProducts.length == 0 ||
                this.selectedStopProducts.every(stopProduct => info[STOP_PRODUCTS_MAP[stopProduct]]);

      add &= info.name.toLowerCase().indexOf(lowerSearch) != -1;
      add &= (this.myRecipesOnly && info.is_owner) || !this.myRecipesOnly
      if (add) {
        newRecipes.push(info)
      }
      })
      this.recipes = newRecipes;
    }
  }
}
</script>
<style>
@import './common.scss';
</style>
