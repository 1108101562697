<template>
  <v-container>
    <div style="display: grid; grid-template-columns: 25% 50% 25%;">
      <v-text-field
        id='recipeNameSimpleCalc'
        :error-messages="recipeNameErrMsg == '' ? [] : [recipeNameErrMsg]"
        v-model="recipeName"
        :rules="[rules.required, custom_rules.recipeNameUnique, rules.string]"
        :label="text.recipe.recipe_name"
        style="grid-column-start: 2;"
        @click="() => {recipeNameErrMsg = ''}">
      </v-text-field>
    </div>

    <IngredientsTableForRecipe
      id='ingredientsTableSimpleCalc'
      :isChanging="true"
      :products="products"
      :updateProduct="updateProduct"
      :removeProduct="removeProduct"
      :addProduct="addProduct"/>

    <ProductsProportionTable
      :products="products"
      :saveFinalRecipeProportion="saveFinalRecipeProportion"/>

    <div class="chart-container">
      <div>
        <div class="chart-title">{{ text.calculator.truffel_chart_title }}</div>
        <Bar id="my-chart-id0"
              style="position: relative; height: 250px; width: 100%"
              :options="chart_options"
              :data="chart_data_trufell"/>
      </div>
      <div>
        <div class="chart-title">{{ text.calculator.candy_chart_title }}</div>
        <Bar id="my-chart-id1"
              style="position: relative; height: 250px; width: 100%"
              :options="chart_options"
              :data="chart_data_candy"/>
      </div>
      <div>
        <div class="chart-title">{{ text.calculator.ganasge_chart_title }}</div>
        <Bar id="my-chart-id2"
              style="position: relative; height: 250px; width: 100%"
              :options="chart_options"
              :data="chart_data_trufell"/>
      </div>
    </div>

    <span class="recipe-info-tables-container">
      <div class="recipe-wave-container">
        <img class="recipe-wave"/>
      </div>

      <CalcAmountTable
        style="z-index: 5"
        :products="products"
        :recipeName="recipeName"
        :amountTable="amountTable"/>

      <div class="simple-calc-db-save-border" style="z-index: 5">
        <div class="simple-calc-db-save-button-container">
          <button
            class="simple-calc-db-save-button common-button"
            :style="$store.getters.LOADING || products.length == 0 || recipeSaveStatus == save_status.SAVED ?
                   'opacity: 60%': ''"
            @click="saveRecipe()"
            :disabled="products.length == 0 || $store.getters.LOADING || recipeSaveStatus == save_status.SAVED">
            {{ text.calculator.save_to_db }}
          </button>
          <div class="simple-calc-db-save-star">
            <img class="simple-calc-db-save-star-icon" src="./icons/star_big.svg" />
          </div>
        </div>
      </div>

    </span>
  </v-container>
</template>
<script >
import IngredientsTableForRecipe from './IngredientsTableForRecipe.vue'
import ProductsProportionTable from './ProductsProportionTable.vue'
import CalcAmountTable from './CalcAmountTable.vue'
import saveReceptToDB from './save_recipe_to_db'
import { COMPONENT_NAME, ALERT_TYPE, BEST_VEL_TRUFELLL,
         BEST_VEL_CANDY, BEST_VEL_GANASHE, SAVE_STATUS } from './define.js'
import all_text from './ru-text'
import all_rules from './rules'

import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'Calculator',
  components: {
    IngredientsTableForRecipe,
    ProductsProportionTable,
    CalcAmountTable,
    // SaveRecipeToDB,
    Bar
  },
  data() {
    return {
      chart_data: {
        datasets: [{
          label: all_text.calculator.real_value,
          backgroundColor: [],
          data: []
        },
        {
          label: all_text.calculator.border_value,
          backgroundColor: ['rgb(164, 126, 90)','rgb(164, 126, 90)','rgb(164, 126, 90)',
                            'rgb(164, 126, 90)','rgb(164, 126, 90)','rgb(164, 126, 90)'],
          data: []
        }]
      },

      chart_data_trufell: {},
      chart_data_candy: {},
      chart_data_ganashe: {},

      chart_options: {
        responsive: true,
        barThickness: 8,
        // barPercentage: 0.6,
        elements: {
          bar: {
            inflateAmount: 2
          }
        },
        scales: {
          x: { labels: []},
          y: {
              min: 0,
              max: 100,
          }
        },
        plugins: {
          legend: {
            display: false,
          }
        }
      },

      products: [],
      finalRecipeProportion: {},
      amountTable: [],

      componentsName: COMPONENT_NAME,

      recipeName: "",
      recipeID: null,
      recipeNameErrMsg: '',

      recipeSaveStatus: SAVE_STATUS.CREATED_NOT_SAVED,
      save_status: SAVE_STATUS,

      text: all_text,
      rules: all_rules,

      custom_rules: {
        recipeNameUnique: value => {
          let recipes = this.$store.getters.RECIPES != null ? this.$store.getters.RECIPES : [];
          let temp = value ? value.trim() : value;
          return (!!recipes.every(info => info.name != temp) || 'Такое имя уже существует');
        }
      },
    }
  },
  created() {
    this.chart_data_trufell = this.chart_data
    this.chart_data_candy = this.chart_data
    this.chart_data_ganashe = this.chart_data

    Object.keys(this.componentsName).forEach((component) => {
      this.chart_options.scales.x.labels.push(this.componentsName[component])

      this.chart_data_trufell.datasets[1].data.push(BEST_VEL_TRUFELLL[component])
      this.chart_data_candy.datasets[1].data.push(BEST_VEL_CANDY[component])
      this.chart_data_ganashe.datasets[1].data.push(BEST_VEL_GANASHE[component])
    })
  },
  watch: {
    recipeName() {
      if (this.recipeSaveStatus == SAVE_STATUS.SAVED) {
        this.recipeSaveStatus = SAVE_STATUS.EDITED_NOT_SAVED
      }
    }
  },
  methods: {
    updateProduct(idx, updated_item, key=null) {
      if (key) {
        if (key >= 0) {
          this.products[idx][key] = updated_item
        } else {
          this.products.at(idx)[key] = updated_item
        }
      } else {
        if (key >= 0) {
          this.products[idx] = updated_item
        }
      }
    },
    addProduct(new_item, before_idx=null) {
      if (before_idx) {
        this.products.splice(before_idx, 0, new_item);
      } else {
        this.products.push(new_item);
      }
    },
    removeProduct(idx) {
      this.products.splice(idx, 1);
    },
    saveRecipe() {
      let results = saveReceptToDB(this.recipeSaveStatus == SAVE_STATUS.CREATED_NOT_SAVED,
                                   this.recipeName, this.products,
                                   this.finalRecipeProportion, this.recipeID)
      results.then((res) => {
        if (res.otherError) {
          this.$store.commit("RAISE_ALERT",
            {'message': res.otherError,
            'status': ALERT_TYPE.ERROR})
          let el = document.getElementById('ingredientsTableSimpleCalc');
          if (el) {
            el.scrollIntoView({behavior: "smooth"});
          }
        } else if (res.recipeNameErr) {
          this.recipeNameErrMsg = res.recipeNameErr
          let el = document.getElementById('recipeNameSimpleCalc');
          if (el) {
            el.scrollIntoView({behavior: "smooth"});
          }
        } else if (res.status == 200 || res.status == 201) {
          this.recipeSaveStatus = SAVE_STATUS.SAVED
          this.recipeID = res.data.id
          this.$store.commit("RAISE_ALERT",
                  {'message': this.text.recipe.recipe_saved,
                    'status': ALERT_TYPE.SUCCESS})
        } else {
          this.$store.commit("RAISE_ALERT",
          {'message': res.otherError,
          'status': ALERT_TYPE.ERROR})
        }
      }).catch((err) => {
        this.$store.commit("RAISE_ALERT",
          {'message': this.text.common_error_message,
           'status': ALERT_TYPE.ERROR})
      })
    },
    saveFinalRecipeProportion(finalProportion) {
      if (this.recipeSaveStatus == SAVE_STATUS.SAVED)
        this.recipeSaveStatus = SAVE_STATUS.EDITED_NOT_SAVED

      let new_chart_data_truffell = {backgroundColor: [], data: [], label: this.text.calculator.real_value}
      let new_chart_data_candy = {backgroundColor: [], data: [], label: this.text.calculator.real_value}
      let new_chart_data_ganashe = {backgroundColor: [], data: [], label: this.text.calculator.real_value}

      Object.keys(this.componentsName).forEach((component) => {
        this.finalRecipeProportion[component] = finalProportion[component]
        let new_val = finalProportion[component] ? parseFloat(finalProportion[component], 2) : 0

        new_chart_data_truffell.data.push(new_val)
        // new_chart_data_truffell.backgroundColor.push(new_val < BEST_VEL_TRUFELLL[component] ?
        //                                              'rgb(92, 112, 75)' : 'rgb(111, 43, 33)')
        new_chart_data_truffell.backgroundColor.push('rgb(92, 112, 75)')
        new_chart_data_candy.data.push(new_val)
        // new_chart_data_candy.backgroundColor.push(new_val < BEST_VEL_CANDY[component] ?
        //                                           'rgb(92, 112, 75)' : 'rgb(111, 43, 33)')
        new_chart_data_candy.backgroundColor.push('rgb(92, 112, 75)')
        new_chart_data_ganashe.data.push(new_val)
        // new_chart_data_ganashe.backgroundColor.push(new_val < BEST_VEL_GANASHE[component] ?
        //                                             'rgb(92, 112, 75)' : 'rgb(111, 43, 33)')
        new_chart_data_ganashe.backgroundColor.push('rgb(92, 112, 75)')
      })

      this.chart_data_trufell = {datasets: [new_chart_data_truffell, this.chart_data.datasets[1]]}
      this.chart_data_candy = {datasets: [new_chart_data_candy, this.chart_data.datasets[1]]}
      this.chart_data_ganashe = {datasets: [new_chart_data_ganashe, this.chart_data.datasets[1]]}
    },
  }
}
</script>

<style>

.chart-container {
  display: grid;
  grid-template-columns: auto auto auto;
}

.recipe-wave {
  content: url("./icons/wave_6.svg");
  width: 110%;
}

.recipe-wave-container {
  overflow: hidden;
  height: -webkit-fill-available;
  width: 150%;
  position: absolute;
  margin-top: -30px;
  z-index: 1;
  opacity: 0.7;
  margin-right: -75%;
  right: 50%;
}

.recipe-info-tables-container {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 80px;
  margin-bottom: 250px;
}

.simple-calc-db-save-border {
  height: fit-content;
  padding: 0px !important;
  border-style: solid solid solid solid;
  border-color: var(--v-green-base);
  border-radius: 0px 10px 10px 0px;
}

.simple-calc-db-save-button-container {
  display: grid;
  grid-template-columns: auto auto;
}

.simple-calc-db-save-star-icon {
  align-self: center;
  width: 30px;
  height: 30px;
}

.simple-calc-db-save-star {
  display: flex;
  justify-content: center;
  width: 40px;
  border-radius: 20px 6px 6px 20px;
  background-color: var(--v-green-base);
  border-color: var(--v-green-base);
  border-style: solid;
  justify-self: end;
}

.simple-calc-db-save-button {
  margin: 5px 0px 5px 10px;
  height: 35px;
  font-size: 14px;
  justify-self: start;
  width: 85%;
}

.chart-title {
  color: var(--v-green-base);
  font-size: 13px;
  text-align: center;
  font-weight: bold;
}

@media (max-width: 950px) {

  .recipe-wave-container {
    width: 300%;
    margin-right: -100%;
  }

  .recipe-wave {
    width: 130%;
  }

  .recipe-info-tables-container {
    display: grid;
    grid-template-columns: 100%;
    margin-bottom: 100px;
  }

  .chart-container {
    display: grid;
    grid-template-columns: 100%;
  }
}
</style>
