<template>
  <v-app>
      <v-toolbar
        color="main_background"
        :max-height="this.$vuetify.breakpoint.width >= mobile_border ? '112' : '56'">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="this.$vuetify.breakpoint.width < mobile_border">
        <img
          src="./components/icons/menu.svg"
          height="30px"
          width="30px" >
      </v-app-bar-nav-icon>

      <v-toolbar-title class="main-title">
        {{ text.main_name }}
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <template v-slot:extension v-if="this.$vuetify.breakpoint.width >= mobile_border">
        <v-tabs align-with-title align-tabs="title" >
          <v-tab to="/">{{ text.navigation.main_page }}</v-tab>
          <v-tab to="/calculator" v-if="$store.getters.IS_AUTHORIZED">{{ text.navigation.calculator }}</v-tab>
          <v-tab to="/products" v-if="$store.getters.IS_AUTHORIZED">{{ text.navigation.products }}</v-tab>
          <v-tab to="/recipes" v-if="$store.getters.IS_AUTHORIZED">{{ text.navigation.recipes }}</v-tab>
          <v-tab to="/profile" v-if="$store.getters.IS_AUTHORIZED">{{ text.navigation.user_page }}</v-tab>
          <v-tab to="/contacts" v-if="$store.getters.IS_AUTHORIZED">{{ text.navigation.contacts }}</v-tab>
          <!-- <v-tab to="/singup" v-if="!$store.getters.IS_AUTHORIZED">{{ text.navigation.singup }}</v-tab> -->
          <v-tab to="/login" v-if="!$store.getters.IS_AUTHORIZED">{{ text.navigation.login }}</v-tab>
        </v-tabs>
      </template>

      <v-btn
        icon
        v-if="this.$store.getters.IS_AUTHORIZED"
        to="/profile">
        <img
          src="./components/icons/avatar_green.svg"
          height="25px"
          width="25px" >
      </v-btn>
    </v-toolbar>


    <v-navigation-drawer
      v-model="drawer"
      color="main_background"
      absolute
      temporary
      v-if="this.$vuetify.breakpoint.width < mobile_border">
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="nav-text-active">
          <v-list-item to="/">
              <v-list-item-title class="nav-text">{{ text.navigation.main_page }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/calculator" v-if="this.$store.getters.IS_AUTHORIZED">
              <v-list-item-title class="nav-text">{{ text.navigation.calculator }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/products" v-if="this.$store.getters.IS_AUTHORIZED">
            <v-list-item-title class="nav-text">{{ text.navigation.products }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/recipes" v-if="this.$store.getters.IS_AUTHORIZED">
            <v-list-item-title class="nav-text">{{ text.navigation.recipes }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/profile" v-if="this.$store.getters.IS_AUTHORIZED">
            <v-list-item-title class="nav-text">{{ text.navigation.user_page }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/contacts" v-if="this.$store.getters.IS_AUTHORIZED">
            <v-list-item-title class="nav-text">{{ text.navigation.contacts }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/singup" v-if="!this.$store.getters.IS_AUTHORIZED">
            <v-list-item-title class="nav-text">{{ text.navigation.singup }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/login" v-if="!this.$store.getters.IS_AUTHORIZED">
            <v-list-item-title class="nav-text">{{ text.navigation.login }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main :style="{background: $vuetify.theme.themes['light'].main_background}">
      <v-container fluid >
          <v-alert
            :value="this.$store.getters.ALERT_MESSAGE != null"
            style="position: fixed; right: 0%; top: 0%; z-index: 110"
            border="left"
            :color="this.$store.getters.ALERT_COLOR + ' lighten-2'"
            dark
          >
            <pre>{{this.$store.getters.ALERT_MESSAGE}}</pre>
          </v-alert>
          <v-progress-circular
            v-if="this.$store.getters.LOADING"
            :size="50"
            color="green"
            style="position: fixed; right: 50%; top: 50%; z-index: 2"
            indeterminate
          ><v-icon color="green">mdi-cupcake</v-icon></v-progress-circular>
        <router-view>
        </router-view>
      </v-container>
    </v-main>

    <v-footer>
      <v-btn>
        <span>Контакты</span>
        <v-icon>mdi-history</v-icon>
      </v-btn>
    </v-footer>
  </v-app>
</template>

<script>
import { MOBILE_BORDER } from './components/define.js'
import all_text from './components/ru-text'
export default {
  name: 'App',
  beforeCreate() {
    this.$store.commit("initializeStore");
  },
  data: () => ({  
    drawer: false,
    group: null,
    mobile_border: MOBILE_BORDER,
    text: all_text
  }),

  watch: {
    group() {
      this.drawer = false
    },
  methods: {
  },
  },
}
</script>
<style>

.menu-text {
  font-size: 16px;
}
/* .tile-text {
  font-size: 25px;
} */
.menu-text {
  font-size: 16px;
}

.nav-text-active {
  caret-color: var(--v-green-base) !important;
  color: var(--v-green-base) !important;
  font-weight: 600;
}

.nav-text {
  color: var(--v-green-base) !important;
  font-weight: 600 !important;
}

.main-title {
  color: var(--v-green-base);
  font-weight: 600;
}
</style>
