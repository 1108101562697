const required = value => {
    value = (value && (typeof value === 'string' || value instanceof String)) ? 
             value.trim() : value
    return value != undefined || 'Необходимо ввести значение';
};

const email = value => {
        const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
        return pattern.test(value) || 'Введите корректную почту'
    };

const password = value => {
        const pattern = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
        return pattern.test(value) || 'Пароль должен сожержать не менее 8 символов, хотя бы одну цифру, букву и специальный символ'
    };

const double = value => {
        const pattern = /^\d*(\.\d\d*)?$/
        return pattern.test(value) || 'Введите значение цифрами, формата 00.00'
    };

const string = value => {
        const pattern = /^[^(){}[\]`"';:\\^]*$/
        return pattern.test(value) || 'Введите значение вида без cимволов \\ (){}[]`"\';:^'
    };

const counter = value => value <= 100000 || 'Максимальный объем символов 100000';

const doubleWithZero = value => {
        const pattern = /^\d*(\.\d\d*)?$/
        return value == undefined || pattern.test(value) || 'Введите значение цифрами, формата 00.00'
    };

const moreThenZero = value => {
        return (value != undefined && parseFloat(value) > 0) || 'Введите значение больше 0'
    };

export default {required, email, password, string, double, doubleWithZero, moreThenZero, counter}