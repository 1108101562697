<template>
    <v-container>
      <h1 style="text-align: center">
        Скоро здесь будет крутая программа для кондитеров {{ text.main_name }}
      </h1>
    </v-container>
</template>

<script>
import all_text from './ru-text'
 export default {
    data: () => ({
      text: all_text,
      alignments: [
        'start',
        'center',
        'end',
      ],
    }),
  }
</script>

<style>
.titleContainer {
  text-align: center;
  margin-top: 150px;
  margin-bottom: 200px;
  /* background-color: cornflowerblue; */
}
.titleAll {
  font-size: 50px !important;
  justify-content: center;
}
.titleComment {
  margin-top: 20px;
  justify-content: center;
  font-size: 20px;
}
.titleButtonRow {
  margin-top: 40px;
  justify-content: center;
}
.capabilityContainer {
  margin: 100px;
  margin-bottom: 200px;
}
.capabilytyTitle {
  font-size: 30px;
  margin-top: 80px;
  justify-content: center;
}
.capabilyty {
  margin-top: 40px;
  font-size: 20px;
  justify-content: center;
}
.offersContainer {
  margin: 100px;
}
.offersTitle {
  font-size: 30px;
  margin-top: 80px;
  margin-bottom: 50px;
  justify-content: center;
}
.offersCardTitle {
  text-align: center;
  margin-top: 80px;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 20px;
  height: 180px;
}
.offersCardButton {
  text-align: center;
}
.contactContainer {
  margin-top: 300px;
  margin-bottom: 20px;
}
.contactTitle {
  font-size: 20px;
  margin: 10px;
}
.contactRow {
  justify-content: center;
}
.contactContent {
  font-size: 15px;
  margin: 20px;
}
</style>
