<template>
  <v-container fluid fill-height class="mt-10">
    <v-row justify="center">
      <h1>{{message}}</h1>
    </v-row>
    <v-row justify="center" class="mt-5">
      <h5><pre>{{second_message}}</pre></h5>
    </v-row>
    <v-row justify="center" v-if="!this.$store.getters.IS_AUTHORIZED">
      <button v-if="activated"
          class="common-button"
          style="padding: 3px 10px;"
          text @click="() => { this.$router.push('/login') }">
          {{ text.navigation.login }}
      </button>
      <v-btn v-else
          class="common-button mt-5"
          style="padding: 3px 10px;"
          @click="() => { this.$router.push('/singup') }">
          {{ text.navigation.singup }}
      </v-btn>
    </v-row>
  </v-container>
</template>
<script>
import all_text from './ru-text'
export default {
  name: 'FinishActivation',
  data() {
    return {
      message: "",
      second_message: "",
      activated: false,
      text: all_text.login
    }
  },
  created() {
    if (this.$route.fullPath.includes('activate')) {
      this.$store.dispatch('ACTIVATE', {'uid': this.$route.params.uid, 'token': this.$route.params.token}).then((res) => {
        if (res.status == 204) {
          this.message = this.text.successful_activation
          this.activated = true
        }
      }).catch(() => {
        this.message = this.text.unsuccessful_activation
        this.second_message = this.text.unsuccessful_activation_help
      });
    }
    if (this.$route.fullPath.includes('email/confimation')) {
      this.$store.dispatch('CONFIRM_CHANGE_EMAIL', {'uid': this.$route.params.uid, 'token': this.$route.params.token}).then((res) => {
        if (res.status == 204) {
          this.message = this.text.successful_confirmation_email
          this.activated = true
        }
      }).catch(() => {
        this.message = this.text.somthing_went_wrong
        this.second_message = this.text.somthing_went_wrong_help
      });
    }
  },
};
</script>

<style></style>
