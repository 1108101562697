<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width=300px>
      <v-card color="orange">
        <v-card-text class="recipe-remove-text">
          {{ text.recipe.remove_confirmation }}
        </v-card-text>
        <v-card-actions style="display: flex; justify-content: center;">
            <button @click="removeAndClose()" class="recipe-remove-button common-button" style="margin-right: 7px;">
              {{ text.remove }}
            </button>
            <button @click="closeDialog(false)" class="recipe-remove-button common-button" style="margin-left: 7px;">
              {{ text.cancel }}
            </button>
        </v-card-actions>
      </v-card>    
    </v-dialog>
  </v-row>
</template>

<script>
import {  ALERT_TYPE } from './define.js'
import all_text from './ru-text'
export default {
  props: {
    recipeInfo: {
      Object
    },
    dialog: {
      type: Boolean,
    },
    closeDialog: {
      type: Function
    }
  },
  name: 'DeleteProductDialog',
  data() {
    return {
      text: all_text
    }
  },
  methods: {
    removeAndClose() {
      this.$store.dispatch('REMOVE_RECIPE', {'id': this.recipeInfo.id,
                                             'name': this.recipeInfo.name}).then((res) => {
        if (res.status == 200) {
          this.$store.commit("RAISE_ALERT",
                              {'message': this.text.recipe.remove_succesful,
                              'status': ALERT_TYPE.SUCCESS})
        } else {
          this.$store.commit("RAISE_ALERT",
                              {'message': this.text.recipe.common_error_message,
                              'status': ALERT_TYPE.ERROR})
        }
      });
      this.closeDialog(true);
    },
  }
}
</script>
<style>
.recipe-remove-button {
  border: solid;
  border-color: var(--v-main_background-base);
  padding: 3px 10px;
  border-color: var(--v-main_background-base) !important;
}

.recipe-remove-button:hover {
  background-color: var(--v-main_background-base) !important;
  color: var(--v-orange-base) !important;
}

.recipe-remove-text {
  text-align: center !important;
  padding-top: 20px !important;
  font-size: 16px  !important;
  color: var(--v-main_background-base) !important;
}

.err-msg {
  padding-left: 15px;
  margin-top: -10px;
  font-size: 12px;
  color: #ff5252;
}
</style>
