<template>
  <v-container>
    <v-dialog
        v-model="remove_dialog"
        :fullscreen="$vuetify.breakpoint.width < mobile_border"
        persistent
        max-width="50%">
      <v-card color="main_background">
        <v-toolbar color="green">
          <v-toolbar-title>
            <span style="color: var(--v-main_background-base) !important;">
              {{ text.user.remove_accaunt }}
            </span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-8">
          <v-form v-model="valid">
            <v-text-field
                :label="text.login.current_password_label"
                v-model="current_password"
                :type="showCurrentPassword ? 'text' : 'password'"
                :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showCurrentPassword = !showCurrentPassword"
                @click="showErrMessage = false"/>
          </v-form>
          <div :style="showErrMessage ? 'color: var(--v-red-base)' : 'color: var(--v-main_background-base)'">
            {{ errorMessage }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <button class="common-button change-dialog-button"
              :disabled="!valid"
              :style="!valid ? 'opacity: 60%;' : ''"
              @click="remove()">
            {{ this.text.remove }}
          </button>
          <button class="common-button change-dialog-button ml-2" @click="closeDialog(false)">
            {{ this.text.cancel }}
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { ALERT_TYPE, MOBILE_BORDER } from './define.js'
import all_text from './ru-text'
export default {
  props: {
    closeDialog: {
      type: Function
    },
    remove_dialog: {
      type: Boolean,
    }
  },
  name: 'RemoveUserDialog',
  data() {
    return {
      text: all_text,
      errorMessage: all_text.user.accaunt_wrong_deleted,
      showErrMessage: false,
      current_password: null,
      showCurrentPassword: false,
      valid: false,
      mobile_border: MOBILE_BORDER,
    }
  },
  methods: {
    remove() {
      this.$store.dispatch('DELETE_USER', {'current_password': this.current_password}).then((res) => {
        if (res.status == 204) {
          this.$store.commit("RAISE_ALERT", 
                      {'message': this.text.user.accaunt_succesfully_deleted,
                        'status': ALERT_TYPE.SUCCESS})
          this.closeDialog(true)
        } else {
          this.showErrMessage = true
        }
      }).catch(() => {
        this.showErrMessage = true
      });
    }
  }
}
</script>
<style>
.change-dialog-button {
  padding: 5px 12px;
}
</style>
