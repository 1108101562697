<template>
  <v-container>
    <span class="title-with-shadow-container">
      <span class="title-with-shadow">
        <span class="title-with-shadow-main">
          {{ text.calculator.title }}
        </span>
        <span class="title-with-shadow-shadow">
          {{ text.calculator.title }}
        </span>
      </span>
    </span>

    <div>
      <v-btn-toggle
        class="toggle-buttons"
        style="margin-top: 30px; margin-bottom: 30px;"
        v-model="toggle_calc_type"
        mandatory>
        <v-btn :class="toggle_calc_type == 0 ? 'toggle-one-button active-toggle-green' :
                                               'toggle-one-button not-active-toggle-green'"
          style="border-style: solid; border-color: var(--v-green-base) !important; border-width: 1px 0px 1px 1px;">
          {{ text.calculator.simple }}
        </v-btn>
        <v-btn :class="toggle_calc_type == 1 ? 'toggle-one-button active-toggle-green' :
                                               'toggle-one-button not-active-toggle-green'"
          style="border-style: solid; border-color: var(--v-green-base) !important; border-width: 1px 1px 1px 0px;">
          {{ text.calculator.clever }}
        </v-btn>
      </v-btn-toggle>
    </div>
    <span v-if="toggle_calc_type == 1">
      <CleverCalculator />
    </span>
    <span v-else>
      <SimpleCalculator/>
    </span>
    </v-container>
</template>

<script>
import all_text from './ru-text'
import SimpleCalculator from './SimpleCalculator.vue'
import { COMPONENT_NAME, ALERT_TYPE } from './define.js'
import CleverCalculator from './CleverCalculator.vue'

 export default {
  name: 'Calculator',
  components: {
    SimpleCalculator,
    CleverCalculator
  },
  data() {
    return {
      text: all_text,

        ingredients_ratio: {},

        dialog_simple_new: false, 
        dialog_simple_edit: -1,
        item: {},


        // smart calc
        toggle_calc_type: 1,
        time: 0,
        info: {
          "wet_ingredients": [],
          "sweeteners": [],
          "all_sweetner": 17,
          "chocolates":[],
          "chocolates_ratio": {},
          "wet_ingredients_ratio": {},
          "sweeteners_ratio": {},
          "cacao_batter": [],
          "cacao_batter_ratio": {},
          "butter": [],
          "butter_ratio": [],
          "alcohol": [],
          "alcohol_ratio": {},
          "batter": [],
          "batter_ratio": {},
          "components":
          {
              "cacao": 0, "sugar": 0, "soft_fats": 0, "water": 0, "alcohol": 0, "d_s": 0
          }
        },
        componentsName: COMPONENT_NAME,
        // сделвть это на енуме
        componentsEdit: 0,

        dialog: false,
        enableCalculating: false,
        addItem: false,
        isResults: false,

        restrictionEdit: true,
        productProportion: [],
        products: [], // {'categoryy': '', 'name': '', 'edited': true/false}
        // productMatch: {"chocolate": 0, 'glucose': 1, 'cream': 2, 'puree': 3},
        // newProductMatch: [],


// goods
        // name of area of goods - productMatch
        // name of goods - exiting - existingProductsIndex, not existng - ?
        // components value existing - existingProductInfo, not existng - ? 
// x, y, z
        // value

        editedItem: 0,
        displayError: false,
        switch1: true,
        alignments: [
          'start',
          'center',
          'end',
        ],

        categoryies: [
          'chocolate', 'puree', 'cream', 'glucose', 'other'
        ],

        rules: {
          required: value => !!value || 'Необходимо ввести значение',
          counter: value => value <= 100000 || 'Максимальный объем символов 100000',
          double: value => {
            const pattern = /^\d*(\.\d\d*)?$/
            return pattern.test(value) || 'Введите значение вида 1111.1111'
          },
        },
      }
    },
    computed: {
      existingProductInfo() {
        // return this.$store.getters.PRODUCTS;
        return [];
      },
    },
    created() {
      this.$store.commit("SET_LOADING", true)
      if (this.$store.getters.PRODUCTS == null) {
        this.$store.dispatch('GET_PRODUCTS').then((res) => {
          this.$store.commit("SET_LOADING", false || this.$store.getters.RECIPES == null)
          if (res.status != 200) {
            this.$store.commit("RAISE_ALERT", 
                                {'message': this.text.common_error_message,
                                'status': ALERT_TYPE.ERROR})
          }
        }).catch((err) => {
          console.log('PRODUCTS', err.response)
          this.$store.commit("RAISE_ALERT", 
                                {'message': this.text.common_error_message,
                                'status': ALERT_TYPE.ERROR})
        });
      }
      if (this.$store.getters.RECIPES == null) {
        this.$store.dispatch('GET_RECIPES').then((res) => {
          this.$store.commit("SET_LOADING", false || this.$store.getters.RECIPES == null)
          if (res.status != 200) {
            this.$store.commit("RAISE_ALERT", 
                                {'message': 'Что-то пошло не так, проверьте все и повторите попытку. \
                                              Если она будет неудачной снова, то напишите в поддержку.',
                                'status': ALERT_TYPE.ERROR})
          }
        }).catch((err) => {
          console.log('RECIPES', err.response)
          this.$store.commit("RAISE_ALERT", 
                                {'message': 'Что-то пошло не так, проверьте все и повторите попытку. \
                                              Если она будет неудачной снова, то напишите в поддержку.',
                                'status': ALERT_TYPE.ERROR})
        });
      }
      this.$store.commit("SET_LOADING", this.$store.getters.PRODUCTS == null || this.$store.getters.PRODUCTS == null)
    },
    methods: {
    }
  }
</script>
<style>
@import './common.scss';
.active-toggle-green {
  color: var(--v-main_background-base) !important;
  background-color: var(--v-green-base) !important;
  opacity: 1 !important;
}

.not-active-toggle-green {
  color: var(--v-green-base) !important;
  background-color: var(--v-main_background-base) !important;
  opacity: 1 !important;
}

</style>
