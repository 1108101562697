 <template>
  <v-container v-if="userData">
    <span class="title-with-shadow-container">
      <span class="title-with-shadow">
        <span class="title-with-shadow-main">
          {{ text.navigation.user_page }}
        </span>
        <span class="title-with-shadow-shadow">
          {{ text.navigation.user_page }}
        </span>
      </span>
    </span>
    <v-card color="main_background" style="height: 500px; margin-top: 15px" class="elevation-7">
      <v-navigation-drawer
        color="main_background"
        absolute
        permanent
        :expand-on-hover="this.$vuetify.breakpoint.width < mobile_border">
        <v-list dense>
          <v-list-item-group
              v-model="selectedItem"
              active-class="nav-text-active">
            <v-list-item
                v-for="item in items"
                :key="item.title"
                link>
              <v-list-item-icon >
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="nav-text">{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

      <v-card-text v-if="selectedItem == 0" class="user-info-card-text-padding">
        <div class="three-items">
          <div class="user-data-login-title">{{ text.login.name }}:</div>
          <div class="user-data-login-data">{{ userData.first_name }}</div>
          <v-btn icon
              @click="changeUserData(credential_type.NAME)"
              :disable='$store.getters.LOADING'>
              <img
                  src="./icons/product_edit.svg"
                  height="20px"
                  width="20px" />
          </v-btn>
        </div>
        <div class="three-items mt-5" v-if="userData.last_name != ''">
          <span class="user-data-login-title">{{ text.login.sure_name }}:</span>
          <span class="user-data-login-data">{{ userData.last_name }}</span>
          <v-btn icon
              @click="changeUserData(credential_type.LAST_NAME)"
              :disable='$store.getters.LOADING'>
            <img
                src="./icons/product_edit.svg"
                height="20px"
                width="20px" />
          </v-btn>
        </div>
        <div class="three-items mt-5">
          <span class="user-data-login-title">{{ text.login.user_name }}:</span>
          <span class="user-data-login-data">{{ userData.username }}</span>
          <v-btn icon
              @click="changeUserData(credential_type.USERNAME)"
              :disable='$store.getters.LOADING'>
            <img
                src="./icons/product_edit.svg"
                height="20px"
                width="20px" />
          </v-btn>
        </div>
        <div class="three-items mt-5">
          <span class="user-data-login-title">{{ text.login.email }}:</span>
          <span class="user-data-login-data">{{ userData.email }}</span>
          <v-btn icon
              @click="changeUserData(credential_type.EMAIL)"
              :disable='$store.getters.LOADING'>
            <img
                src="./icons/product_edit.svg"
                height="20px"
                width="20px" />
          </v-btn>
        </div>
        <div class="mt-5 mb-5">
            <button class="common-button change-password-button"
                @click="changeUserData(credential_type.PASSWORD)">
              {{ text.login.change_password }}
            </button>
        </div>
      </v-card-text>
      <v-card-text v-if="selectedItem == 1" class="user-info-card-text-padding">
        <div style="display: grid; grid-template-columns: 50% 50%;">
          <span class="user-data-title pr-6">{{ text.login.created_products }}</span>
          <span class="user-data-data pr-6">{{ userData.own_products }}</span>

          <span class="user-data-title pr-6 mt-5">{{ text.login.created_recipes }}</span>
          <span class="user-data-data pr-6 mt-5">{{ userData.own_recipes }}</span>

          <span class="user-data-title pr-6 mt-5">{{ text.login.user_tarif }}</span>
          <span class="user-data-data pr-6 mt-5">{{ userData.own_plan }}</span>

          <span class="user-data-title pr-6 mt-5">{{ text.login.plane_finish_after }}</span>
          <span class="user-data-data pr-6 mt-5">
            {{ userData.plan_day_left }}
            <span v-if="userData.plan_day_left == 1">
              {{ text.login.day }}
            </span>
            <span v-else>
              {{ text.login.days }}
            </span>
          </span>
        </div>
      </v-card-text>
      <v-card-text
        v-if="selectedItem == 2"
        class="user-info-card-text-padding">
        <div style="display: grid;">
          <div class="mt-5">
            <button class="common-button change-password-button" @click="logOut()">
              {{ text.login.logout }}
            </button>
          </div>
          <div class="mt-5">
            <button class="common-button change-password-button" @click="remove_dialog = true">
              {{ text.login.remove_acc }}
            </button>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <ChangeDialog
        :change_dialog="change_creadentials_dialog"
        :closeDialog="closeCredentialChangeDialog"
        :change_type="credential_change_type"/>

    <RemoveUserDialog
        :closeDialog="closeRemoveDialog"
        :remove_dialog="remove_dialog"/>

  </v-container>
</template>
<script>
import { ALERT_TYPE, CREDENTIAL_TYPE, MOBILE_BORDER } from './define.js'
import ChangeDialog from './ChangeDialog.vue'
import RemoveUserDialog from './RemoveUserDialog.vue'
import all_text from './ru-text'
export default {
  name: 'UserProfile',
  components: {
    ChangeDialog,
    RemoveUserDialog
  },
  data() {
    return {
      userData: {},
      new_password: null,
      re_new_password: null,
      current_password: null,
      change_password_dialog: false,
      showReNewPassword: false, 
      showNewPassword: false, 
      showOldPassword: false,
      errorMessage: '',
      credential_change_type: null,

      change_creadentials_dialog: false,

      remove_dialog: false,
      showCurrentPassword: false,

      credential_type: CREDENTIAL_TYPE,

      mobile_border: MOBILE_BORDER,
      text: all_text,

      items: [
        { title: all_text.login.user_data, icon: 'mdi-home-city' },
        { title: all_text.login.user_activity, icon: 'mdi-account' },
        { title: all_text.login.manage_acc, icon: 'mdi-account-group-outline' },
      ],

      selectedItem: 0,
    }
  },
  created() {
    this.$store.dispatch('GET_USER_PROFILE').then((res) => {
      this.userData = this.$store.getters.USER
    }).catch((err) => {
      this.$store.commit("RAISE_ALERT", 
                          {'message': this.text.common_error_message,
                           'status': ALERT_TYPE.ERROR})
    });
  },
  methods: {
    changeUserData(creadential_type) {
      this.change_creadentials_dialog = true; 
      this.credential_change_type = creadential_type;
    },
    closeRemoveDialog(success) {
      this.remove_dialog = false
      if (success) {
          this.$router.push('login')
      }
    },
    passwordReset() {
      this.$store.dispatch('CHANGE_PASSWORD', {'new_password': this.new_password, 
                                                're_new_password': this.re_new_password,
                                                'current_password': this.current_password}).then((res) => {
        if (res.status == 204) {
          this.$store.commit("RAISE_ALERT", 
                      {'message': this.text.login.password_changed,
                      'status': ALERT_TYPE.SUCCESS})
          this.remove_dialog = false
          this.$router.push('login')
        } else {
          this.$store.commit("RAISE_ALERT", 
              {'message': this.text.login.password_change_fail,
              'status': ALERT_TYPE.ERROR})
        }
      }).catch((err) => {
        this.$store.commit("RAISE_ALERT", 
                    {'message': this.text.login.password_change_fail,
                    'status': ALERT_TYPE.ERROR})
      });
    },
    logOut() {
      this.$store.dispatch('LOGOUT');
      this.$router.push('/login');
    },
    sentEmail() {
      this.$store.dispatch('SENT');
    },
    closeCredentialChangeDialog() {
      this.change_creadentials_dialog = false
      this.credential_change_type = null
    }
  }
}
</script>

<style>
.stop-product-comment {
  font-size: 12px;
}

.stop-product-block {
  align-items: center;
  display: flex;
}

.nav-text-active {
  caret-color: var(--v-green-base) !important;
  color: var(--v-green-base) !important;
  font-weight: 600;
}

.nav-text {
  color: var(--v-green-base) !important;
  font-weight: 600 !important;
}

.user-data-title {
  color: var(--v-green-base);
}

.user-data-data {
  color: var(--v-green-base);
  font-weight: 600;
  justify-self: center;
}

.change-password-button {
  padding: 5px 13px;
}

.user-info-card-text-padding {
  padding-left: 300px !important;
}

.three-items {
  display: grid;
  grid-template-columns: 30% 60% 10%;
}

.user-data-login-title {
  color: var(--v-green-base);
  align-self: center;
  margin-right: 10px;
}

.user-data-login-data {
  color: var(--v-green-base);
  font-weight: 600;
  align-self: center;
}

@media (max-width: 950px) {
  .user-info-card-text-padding {
    padding-left: 80px !important;
  }

  .user-data-login-title {
    grid-column-start: 1;
    grid-column-end: two;
    margin-bottom: 5px;
  }

  .user-data-login-data {
    justify-self: start;
    align-self: center;
  }

  .three-items {
    grid-template-columns: 80% 20%;
  }
}

</style>
