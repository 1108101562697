<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width=500px>

      <v-progress-circular
        v-if="loading"
        :size="50"
        color="#a96133"
        style="position: fixed; right: 50%; top: 50%; z-index: 2"
        indeterminate
      ><v-icon color="#a96133">mdi-cupcake</v-icon></v-progress-circular>

      <v-card color="green">
        <v-card-text class="remove-text" v-if="recipes.length === 0">
          <span>{{ text.product.remove_confirmation }}</span>
        </v-card-text>
        <v-card-text v-else style="padding-top: 20px !important;">
          <div class="remove-product-in-recipes-text">
            {{ text.product.product_in_recipes }}
          </div>
          <ul
            v-for="(info, index) in this.recipes"
            :key="info.name + '_new_product_' + index"
            class='remove-recipes-text'>
            <li>
                {{ info.name }}
            </li>
          </ul>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
            <button
              v-if="recipes.length === 0"
              class="common-button remove-button"
              @click="closeDialog(true, true)">
              {{ text.remove }}
            </button>
            <button
              v-else
              class="common-button remove-button"
              @click="removeRecipes(true, true)">
              {{ text.product.recmove_recipes }}
            </button>
            
            <button
              class="common-button remove-button"
              @click="closeDialog(false, false)">
              {{ text.cancel }}
            </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import all_text from './ru-text'
  export default {
    props: {
      recipes: {
        Object
      },
      dialog: {
        type: Boolean,
      },
      closeDialog: {
        type: Function
      }
    },
    name: 'DeleteProductDialog',
    data() {
      return {
        loading: false,
        recipesAmont: 0,
        text: all_text
      }
    },
    created() {
        this.recipesAmont = this.recipes.length
    },
    methods: {
        // TODO: change it 
        removeRecipes() {
            this.loading = true
            let recipes = this.recipes.map((recipe) => { return {'id': recipe.id, 'name': recipe.name }})
            this.$store.dispatch('REMOVE_RECIPES', recipes).then(() => {
                this.closeDialog(true, true)
            }).catch((error) => {
                let message = null
                if (error.response.status == 400 && 'error_messages' in error.response.data) {
                  message = this.text.recipe.remove_recipe_error + ' \n'
                  error.response.data.error_messages.forEach(info => {
                    message += info.name
                    if (info.message.includes('Only owner could delete recipe')) {
                      message += ' - ' + this.text.recipe.only_owner_error + ' '
                    }
                    message += ' \n'
                  });
                  message += this.text.recipe.try_to_remove + ' \n' +
                             this.text.recipe.write_to_support + '\n'
                }
                this.closeDialog(true, false, message)
            });
        },
    }
  }
</script>
<style>
@import './common.scss';

.remove-text {
  padding-top: 20px !important;
  font-size: 17px !important;
  text-align: center;
  color: var(--v-main_background-base) !important;
}

.remove-button {
  margin-left: 10px;
  padding: 5px 10px 5px 10px;
}

.remove-product-in-recipes-text {
  font-size: 17px !important;
  color: var(--v-main_background-base) !important;
}

.remove-recipes-text {
  font-size: 14px !important;
  color: var(--v-main_background-base) !important;
  margin-top: 10px;
  margin-left: 10px !important;
}

.err-msg {
  padding-left: 15px;
  margin-top: -10px;
  font-size: 12px;
  color: #ff5252;
}
</style>
