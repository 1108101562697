const COMPONENT_NAME = {
    "solid_fats": "Твердые жиры",
    "sugar": "Сахара",
    "soft_fats": "Мягкие жиры",
    "water": "Вода",
    "alcohol": "Алкоголь",
    "d_s": "Сухое вещество"
}

const KALLORIES = {
    "fats": "Жиры",
    "proteins": "Белки",
    "carbohydrates": "Углеводы",
    "kilokall": "Килокалории" 
}

const SAVE_STATUS = {
    'N_A': -1,
    'CREATED_NOT_SAVED': 0,
    'SAVED': 1,
    'EDITED_NOT_SAVED': 2
}

// const STOP_PRODUCTS_MAP = {
//     "vegan": "Веганский",
//     "vegetarian": "Вегетарианский",
//     "gluten_free": "Без глютена",
//     "nuts_free": "Не содержит орехов",
//     "peanuts_free": "Не содержит арахиса",
//     "egg_free": "Без яиц",
//     "lactose_free": "Не содержит лактозу",
//     "sugar_free": "Не содержит сахар",
//     "alcohol_free": "Не содержит алкоголь"
// }

const STOP_PRODUCTS_MAP = {
    "Веганский": "vegan",
    "Вегетарианский": "vegetarian",
    "Без глютена": "gluten_free",
    "Не содержит орехов": "nuts_free",
    "Без яиц": "egg_free",
    "Не содержит лактозу": "lactose_free",
    "Не содержит сахар": "sugar_free",
    "Не содержит алкоголь": "alcohol_free"
}

const ALERT_TYPE = {
    'SUCCESS': 'success',
    'ERROR': 'error',
}

const CREDENTIAL_TYPE = {
    'EMAIL': 'email',
    'USERNAME': 'имя пользователя',
    'PASSWORD': 'пароль',
    'NAME': 'имя',
    'LAST_NAME': 'фамилию'
}

const TABLE_ICONS = {
    "solid_fats": "./icons/cacaco.svg",
    "sugar": "./icons/sugar_3.svg",
    "soft_fats": "./icons/butter.svg",
    "water": "./icons/water.svg",
    "alcohol": "./icons/alcohol_table.svg",
    "d_s": "./icons/d_s.svg"
}

const SOLID_OIL_CHOICE = {
    'cacao_butter': 0,
    'coconut_oil': 1,
    'both_oil': 2
}

const BEST_VEL_TRUFELLL = {
    "solid_fats": 18.0,
    "sugar": 17.0,
    "soft_fats": 12.1,
    "water": 24.4,
    "alcohol": 0.2,
    "d_s": 6.5
}

const BEST_VEL_CANDY = {
    "solid_fats": 20.0,
    "sugar": 17.0,
    "soft_fats": 12.1,
    "water": 18.4,
    "alcohol": 0.2,
    "d_s": 10.5
}

const BEST_VEL_GANASHE = {
    "solid_fats": 20.0,
    "sugar": 17.0,
    "soft_fats": 10.1,
    "water": 22.4,
    "alcohol": 0.2,
    "d_s": 8.5
}

const MOBILE_BORDER = 950
const TABLET_BORDER = 1300

export {COMPONENT_NAME, KALLORIES, STOP_PRODUCTS_MAP, 
        SAVE_STATUS, ALERT_TYPE, CREDENTIAL_TYPE, TABLE_ICONS,
        SOLID_OIL_CHOICE, MOBILE_BORDER, TABLET_BORDER,
        BEST_VEL_TRUFELLL, BEST_VEL_CANDY, BEST_VEL_GANASHE};