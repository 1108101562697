<template>
  <v-container style="padding: 0px !important;">
    <hr class="pr-line" />
    <span class="name-main-container">
      <img class="name-img-right" />
      <span class="name-container">
        <img class="name-icon name-icon-align" src="./icons/star_big.svg" />
        <span class="product-name-font-size product-name">
          {{ title }}
        </span>
      </span>
    </span>
    <hr class="pr-line" />
  </v-container>
</template>

<script>
export default {
    props: {
      title: {
        String
      }
    },
  name: 'ProductTitle',
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>

.pr-line {
  position: absolute;
  width: 100%;
  left: 50%;
  margin-left: -50%;
  height: 2px; /* or whatever. */
  background-color: var(--v-green-base);
}

.name-main-container {
  height: 102px;
  display: grid;
  grid-template-columns: 25% 50% 25%;
}

.name-container {
  grid-column-start: 2;
  justify-self: center;
  align-self: center;
}

.product-name {
  color: var(--v-green-base);
  position: relative;
  z-index: 2;
}

.product-name-font-size {
  font-size: 36px;
}

.name-icon {
  position: absolute;
  z-index: 1;
  opacity: 0.7;
}

.name-icon-align {
  height: 98px;
  width: 98px;
  margin-left: -35px;
  margin-top: -20px;
}

.name-img-right {
  content: url("./icons/pattern_right.svg");
  position: absolute;
  right: 50%;
  margin-right: -50%;
}

@media (max-width: 950px) {
  .name-main-container {
    height: 80px;
    display: grid;
    grid-template-columns: 25% 50% 25%;
  }

  .product-name-font-size {
    font-size: 20px;
  }

  .name-icon-align {
    height: 74px;
    width: 74px;
    margin-left: -29px;
    margin-top: -20px;
  }

  .name-img-right {
    content: url("./icons/pattern-right-small.svg");
    position: absolute;
    right: 50%;
    margin-right: -50%;
  }

}
</style>
