<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md5>
        <v-card v-if="$route.fullPath.includes('password')"
            class="elevation-7"
            color="main_background">
          <v-toolbar color="green">
            <v-toolbar-title>
              <span class="user-dialog-title">
                  {{ text.change_password_form }}
                </span>
              </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                  :label="text.new_password"
                  v-model="new_password"
                  :rules="[rules.required, rules.password]"
                  :type="showNewPassword ? 'text' : 'password'"
                  :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showNewPassword = !showNewPassword"
                  @click="errorMessage = ''"/>
              <v-text-field
                  :label="text.new_password_repeate"
                  v-model="re_new_password"
                  :rules="[rules.required, custom_rules.secondPassword]"
                  :type="showReNewPassword ? 'text' : 'password'"
                  :append-icon="showReNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showReNewPassword = !showReNewPassword"
                  @click="errorMessage = ''"/>
            </v-form>
          </v-card-text>
          <v-card-actions>
            {{ errorMessage }}
            <v-spacer></v-spacer>
            <button class="common-button"
                style="padding: 3px 10px;"
                @click="confirmReset()">
              {{ text.done }}
            </button>
          </v-card-actions>
        </v-card>
        <v-card v-if="$route.fullPath.includes('username')"
            class="elevation-7"
            color="main_background">
          <v-toolbar color="green">
            <v-toolbar-title>
              <span class="user-dialog-title">
                {{ text.change_username_form }}
              </span>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                  name="username"
                  label="username"
                  v-model="new_username"
                  @click="errorMessage = ''"/>
            </v-form>
          </v-card-text>
          <v-card-actions>
            {{ errorMessage }}
            <v-spacer></v-spacer>
            <button class="common-button"
                style="padding: 3px 10px;"
                @click="confirmReset()">
                {{ text.done }}
            </button>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { ALERT_TYPE } from './define.js'
import ALL_RULES from './rules'
import all_text from './ru-text'
export default {
  name: 'ResetPassword',
  data() {
    return {
      new_password: null,
      re_new_password: null,
      showNewPassword: false,
      showReNewPassword: false,

      new_username: null,

      text: all_text.login,

      errorMessage: '',
      rules: ALL_RULES,
      custom_rules: {
        secondPassword: value => {
          return value == this.re_new_password || this.text.passwort_is_not_matched
        }
      }       
    }
  },
  methods: {
    confirmReset() {
      if (this.new_username) {
        this.$store.dispatch('CONFIRM_RESET_USERNAME', {'uid': this.$route.params.uid, 
                                                        'token': this.$route.params.token,
                                                        'new_username': this.new_username,
                                                        're_new_username': this.new_username}).then((res) => {
          if (res.status == 204) {
              this.$store.commit("RAISE_ALERT",
                          {'message': this.text.username_changed_succesfully,
                            'status': ALERT_TYPE.SUCCESS})
          }
          this.$router.push('/login/');
        }).catch(() => {
            this.errorMessage = this.text.changing_failed
        });
      }
      if (this.new_password) {
        this.$store.dispatch('CONFIRM_RESET_PASSWORD', {'uid': this.$route.params.uid, 
                                                        'token': this.$route.params.token,
                                                        'new_password': this.new_password,
                                                        're_new_password': this.re_new_password}).then((res) => {
            if (res.status == 204) {
                this.$store.commit("RAISE_ALERT",
                            {'message': this.text.password_changed_succesfully,
                            'status': ALERT_TYPE.SUCCESS})
            }
            this.$router.push('/login/');
        }).catch(() => {
            this.errorMessage = this.text.changing_failed
        });
      }
    }
  }
};
</script>
<style>
</style>
