var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"amount-border"},[(_vm.calc_amount_dialog == false)?_c('div',{staticClass:"amount-button-container"},[_c('div',{staticClass:"amount-star"},[_c('img',{staticClass:"amount-star-icon",attrs:{"src":require("./icons/star_big.svg")}})]),_c('button',{class:_vm.products.length == 0 || _vm.disabled_by_parent || _vm.$store.getters.LOADING ? 
              ['common-disable-text-color common-button calc-amount-button'] : 
              ['common-button calc-amount-button'],attrs:{"disabled":_vm.products.length == 0 || _vm.disabled_by_parent || _vm.$store.getters.LOADING},on:{"click":() => { _vm.calc_amount_dialog = true }}},[_vm._v(" "+_vm._s(_vm.text.recipe.calculate_on_amount)+" ")])]):_c('div',{staticClass:"calc-amount-title"},[_c('div',{staticStyle:{"justify-self":"end"}},[_vm._v(_vm._s(_vm.text.recipe.calculate_on_amount))])]),(_vm.calc_amount_dialog == true)?_c('div',[_c('div',{staticClass:"measure-type-container"},[_c('v-btn-toggle',{staticClass:"toggle-buttons",attrs:{"color":"primary","mandatory":""},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-btn',{class:_vm.step == 0 ? 'toggle-one-button active-toggle-green measure-type-btn' :
                                              'toggle-one-button not-active-toggle-green measure-type-btn',staticStyle:{"border-style":"solid","border-color":"var(--v-green-base) !important","border-width":"1px 0px 1px 1px"},attrs:{"value":0}},[_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.width > _vm.tablet_border ? _vm.text.recipe.by_final_weight : _vm.text.recipe.by_final_weight_short)+" ")]),_c('v-btn',{class:_vm.step == 1 ? 'toggle-one-button active-toggle-green measure-type-btn' :
                                              'toggle-one-button not-active-toggle-green measure-type-btn',staticStyle:{"border-style":"solid","border-color":"var(--v-green-base) !important","border-width":"1px 1px 1px 0px"},attrs:{"value":1}},[_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.width > _vm.tablet_border ? _vm.text.recipe.by_portion_amount : _vm.text.recipe.by_portion_amount_short)+" ")])],1)],1),_c('v-window',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-window-item',{attrs:{"value":0}},[_c('v-form',{ref:"amount_by_weight",attrs:{"validate-on":"submit input"},model:{value:(_vm.valid_by_weight),callback:function ($$v) {_vm.valid_by_weight=$$v},expression:"valid_by_weight"}},[_c('v-card',{staticClass:"clac-amount-card-custom-info",attrs:{"color":"main_background","elevation":"0"}},[_c('v-card-text',{staticStyle:{"height":"170px","display":"flex","align-items":"center"}},[_c('v-text-field',{attrs:{"rules":[_vm.rules.required, _vm.rules.double],"label":_vm.text.recipe.final_weight},model:{value:(_vm.needCandy.needCandyWeight),callback:function ($$v) {_vm.$set(_vm.needCandy, "needCandyWeight", $$v)},expression:"needCandy.needCandyWeight"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('button',{class:!_vm.valid_by_weight ?
                        ['common-disable-text-color', 'common-button'] :
                        ['common-button'],staticStyle:{"padding":"2px 10px"},attrs:{"disabled":!_vm.valid_by_weight,"small":""},on:{"click":function($event){return _vm.riseCalculation()}}},[_vm._v(" "+_vm._s(_vm.text.calculate)+" ")])],1)],1)],1)],1),_c('v-window-item',{attrs:{"value":1}},[_c('v-form',{ref:"amount_by_portion",attrs:{"validate-on":"submit input"},model:{value:(_vm.valid_by_portion),callback:function ($$v) {_vm.valid_by_portion=$$v},expression:"valid_by_portion"}},[_c('v-card',{staticClass:"clac-amount-card-custom-info",attrs:{"color":"main_background","elevation":"0"}},[_c('v-card-text',{staticStyle:{"height":"170px"}},[_c('v-text-field',{attrs:{"rules":[_vm.rules.required, _vm.rules.double],"label":_vm.text.recipe.final_portion_amount},model:{value:(_vm.needCandy.needCandyAmount),callback:function ($$v) {_vm.$set(_vm.needCandy, "needCandyAmount", $$v)},expression:"needCandy.needCandyAmount"}}),_c('v-text-field',{attrs:{"rules":[_vm.rules.required, _vm.rules.double],"label":_vm.text.recipe.one_portion_weight},model:{value:(_vm.needCandy.oneCandyWeight),callback:function ($$v) {_vm.$set(_vm.needCandy, "oneCandyWeight", $$v)},expression:"needCandy.oneCandyWeight"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('button',{class:!_vm.valid_by_portion ?
                        ['common-disable-text-color', 'common-button'] :
                        ['common-button'],staticStyle:{"padding":"2px 10px"},attrs:{"disabled":!_vm.valid_by_portion},on:{"click":function($event){return _vm.riseCalculation()}}},[_vm._v(" "+_vm._s(_vm.text.calculate)+" ")])],1)],1)],1)],1)],1)],1):_vm._e(),(_vm.knownFinalCandyInfo())?_c('v-divider',{attrs:{"thickness":4,"color":"green"}}):_vm._e(),(_vm.knownFinalCandyInfo())?_c('div',{staticStyle:{"margin":"10px","margin-top":"20px"}},[(_vm.amountInfo.needCandyAmount > 0)?_c('v-row',[_c('v-col',{staticClass:"text-subtitle-1",attrs:{"sm":"4","xs":"6"}},[_vm._v(" "+_vm._s(_vm.text.recipe.portion_amount)+" ")]),_c('v-col',{staticClass:"text-subtitle-1",attrs:{"sm":"2","xs":"4"}},[_vm._v(" "+_vm._s(_vm.amountInfo.needCandyAmount)+" ")])],1):_vm._e(),_c('v-row',{staticStyle:{"margin-bottom":"10px"}},[_c('v-col',{staticClass:"text-subtitle-1",attrs:{"sm":"4","xs":"6"}},[_vm._v(" "+_vm._s(_vm.text.recipe.portion_weight)+" ")]),_c('v-col',{staticClass:"text-subtitle-1",attrs:{"sm":"2","xs":"4"}},[_vm._v(" "+_vm._s(_vm.amountInfo.needCandyWeight)+" ")])],1),_c('div',{attrs:{"id":"target-table"}},[_c('table',{staticClass:"calc-amount-table",attrs:{"color":"main_background"}},[_c('tr',[_c('th',{staticClass:"calc-amount-table-pr-name"},[_vm._v(_vm._s(_vm.text.recipe.product))]),_c('th',[_vm._v(_vm._s(_vm.text.recipe.amount_gramm_in_candy))])]),_vm._l((_vm.amountTable),function(item){return _c('tr',{key:item.name + 'tr'},[_c('td',{staticClass:"calc-amount-table-pr-name"},[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.customWeight))])])})],2)]),_c('div',{staticStyle:{"display":"flex","justify-content":"end"}},[_c('button',{staticClass:"common-button",staticStyle:{"padding":"3px 5px 3px 5px","margin-top":"20px","margin-bottom":"20px"},attrs:{"disabled":!_vm.knownFinalCandyInfo() || _vm.$store.getters.LOADING},on:{"click":function($event){return _vm.saveReceptAsPDF()}}},[_vm._v(" "+_vm._s(_vm.text.recipe.save_as_pdf)+" ")])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }