 <template>
    <v-container>
        <span class="stop-product-grid-container">
            <template v-for="icon in icons">
                <span :key="icon.key" :style='pre_tooltip_container_style'>
                    <v-tooltip top :disabled="!smallMode && $vuetify.breakpoint.width > mobile_border">
                        <template v-slot:activator="{ on, attrs }">
                            <span
                                class="stop-product-block"
                                v-bind="attrs"
                                v-on="on">
                                <span
                                    :style="info[icon.key] ? 'opacity: 1; ' + container_class 
                                                           : 'opacity: 0.5; ' + container_class"
                                    :class="smallMode ? '' : 'stop-product-sheet-container'">
                                    <img
                                        :src="icon.path"
                                        class="icon-size"
                                        :width="width_icon"
                                        :height="height_icon" />
                                    <span
                                        v-if="!smallMode && $vuetify.breakpoint.width > mobile_border"
                                        class="stop-product-comment">
                                        {{ icon.comment }}
                                    </span>
                                </span>
                                <span
                                    v-if="!smallMode && $vuetify.breakpoint.width <= mobile_border"
                                    class="stop-product-comment">
                                    {{ icon.comment }}
                                </span>
                            </span>
                        </template>
                        <span>
                            {{ icon.comment }}
                        </span>
                    </v-tooltip>
                </span>
            </template>
        </span>
    </v-container>
</template>
<script>
import { MOBILE_BORDER, TABLET_BORDER } from './define.js'
export default {
    props: {
      info: {
        Object
      },
      smallMode: {
        type: Boolean,
        default: false
      }
    },
    name: 'StopProducts',
    data() {
        return {
            icons: [
                {
                    'key': 'vagan',
                    'path': new URL('./icons/vegan.svg', import.meta.url),
                    'comment': 'Веганский продукт',
                }, 
                {
                    'key': 'alcohol_free',
                    'path': new URL('./icons/alcohol_free.svg', import.meta.url),
                    'comment': 'Не содержит алкоголь',
                },
                {
                    'key': 'egg_free',
                    'path': new URL('./icons/egg_free.svg', import.meta.url),
                    'comment': 'Не содержит яиц',
                },
                {
                    'key': 'nuts_free',
                    'path': new URL('./icons/nuts_free.svg', import.meta.url),
                    'comment': 'Не содержит орехов',
                },
                {
                    'key': 'lactose_free',
                    'path': new URL('./icons/lactose_free.svg', import.meta.url),
                    'comment': 'Не содержит молочные жиры',
                },
                {
                    'key': 'gluten_free',
                    'path': new URL('./icons/gluten_free.svg', import.meta.url),
                    'comment': 'Не содержит глютен',
                },
                {
                    'key': 'vegetarian',
                    'path': new URL('./icons/vegetarian.svg', import.meta.url),
                    'comment': 'Вегетарианский',
                },
                {
                    'key': 'sugar_free',
                    'path': new URL('./icons/sugar_free.svg', import.meta.url),
                    'comment': 'Не содержит добавленного сахара',
                }
            ],
            show: false,
            width_icon: 90,
            height_icon: 90,
            container_class: 'width: 120px; height: 120px',
            pre_tooltip_container_style: 'padding: 10px;',
            mobile_border: MOBILE_BORDER,
            tablet_border: TABLET_BORDER 
        }
    },
    created() {
        if (this.$vuetify.breakpoint.width < this.mobile_border) {
            this.width_icon = 50
            this.height_icon = 50
            this.container_class = 'width: 60px; height: 60px',
            this.pre_tooltip_container_style = 'padding: 3px;'
        } else if (this.$vuetify.breakpoint.width < this.tablet_border) {
            this.width_icon = 70
            this.height_icon = 70
            this.container_class = 'width: 100px; height: 100px',
            this.pre_tooltip_container_style = 'padding: 7px;'
        }

        if (this.smallMode) {
            this.width_icon = 40
            this.height_icon = 40
            this.container_class = 'width: 40px; height: 40px'
            this.pre_tooltip_container_style = 'padding: 3px;'
        }

    }
}
</script>
<style lang="scss" scoped>
.stop-product-comment {
  font-size: 9px !important;
}

.stop-product-block {
  align-items: center;
  display: flex;
}

.stop-product-grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
}

.stop-product-sheet-container {
    border-radius: 10px;
    background-color: var(--v-main_background-base);
    box-shadow: 0px 0px 14px 5px #8d8585;
    display: grid;
    justify-items: center;
    align-items: center;
    text-align: center;
}

@media (max-width: 950px) {
    .stop-product-block {
        align-items: center;
        justify-items: center;
        display: grid;
    }

    .stop-product-comment {
       font-size: 9px !important;
       text-align: center;
    }
}
</style>