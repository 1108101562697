import Vue from 'vue'
import router from './rout.js'

// import KnownProductsTable from './components/KnownProductsTable.vue'
import App from './App.vue'
import vuetify from './plugins/vuetify' // path to vuetify export
import Vuex from 'vuex';
import store from './components/calc_store';
// import './main';
// import Axios from 'axios';

// const globalThis = typeof window === 'object' ? window : global;

Vue.config.productionTip = false
// rgb(119 103 148)
// rgb(101 86 126)
// rgb(89 89 164)
// rgb(82 122 154)
// rgb(90 161 180)
// rgb(119 90 180)



Vue.use(Vuex);
// $body-font-family = 'Barlow Condensed';

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
  // computed: {
  //   ViewComponent () {
  //     return routes[this.currentRoute] || NotFound
  //   }
  // },
  // render (h) { return h(this.ViewComponent) }
}).$mount('#app')

// a.use(calc_store)
