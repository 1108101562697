<template>
  <v-container>

    <button @click="openSettings()" class="settings-open-dialog-button">
      {{ text.settings }}
      <img
        style="margin-left: 10px"
        src="./icons/settings.svg"
        height="30px"
        width="30px" />
    </button>

    <v-dialog
      v-model="settingsDialog"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition">

      <v-card color="main_background">
        <span class="setting-title-container">
          <button
            class="setting-back-button-container"
            @click="closeWithoutChanges()">
            <div class="arrow-left"></div>
            <span class="setting-back-title">{{ text.back }}</span>
          </button>

          <span class="setting-title-text-container">
            <img class="setting-name-icon setting-name-icon-align" src="./icons/star_big.svg" />
            <span class="setting-product-name-font-size setting-product-name">
              {{ text.settings }}
            </span>
          </span>
        </span>

        <span class="settings-checkbox setting-my-product">
          <v-checkbox
            :label="text.product.only_my"
            v-model="my_products_only">
          </v-checkbox>
        </span>

        <span class="setting-checkbox-group-container" v-if="categoriesOn">
          <span class="setting-checkbox-group-title">{{ text.categories }}</span>
          <span
            style="grid-column-start: 2;"
            class="setting-group-checkbox"
            v-for="component in categories_names"
            :key="component">
            <v-checkbox
              v-model="selected_component"
              :label="component"
              :value="component"
            ></v-checkbox>
          </span>
        </span>

        <span class="setting-checkbox-group-container">
          <span class="setting-checkbox-group-title">{{ text.stop_products }}</span>
          <span
            style="grid-column-start: 2;"
            class="setting-group-checkbox"
            v-for="stop_product in allStopProducts"
            :key="stop_product">
            <v-checkbox
              v-model="selected_stop_products"
              :label="stop_product"
              :value="stop_product"
            ></v-checkbox>
          </span>
        </span>

        <span style="display: grid; grid-template-columns: 10% 40% 40% 10%;
                     justify-items: center; align-items: center;">
          <button
            style="grid-column-start: 2;"
            class="setting-card-product-button"
            @click="closeAndApplySettings()">
            {{ text.show }}
          </button>

          <button
            class="setting-card-product-button"
            @click="resetSettings()">
            {{ text.reset }}
          </button>
        </span>

      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {STOP_PRODUCTS_MAP, COMPONENT_NAME, MOBILE_BORDER} from './define'
import all_text from './ru-text'
export default {
    props: {
      applyFilters: {
        Function
      },
      updateSettings: {
        Function
      },
      defaultValues: {
        Object
      },
      categoriesOn: {
        type: Boolean,
        default: true
      }
    },
    name: 'SearchSettings',
    components: {
    },
    data() {
        return {
          settingsDialog: false,
          selected_component: this.defaultValues['selectedCategories'],
          selected_stop_products: this.defaultValues['selectedStopProducts'],
          my_products_only: this.defaultValues['myProductsOnly'],

          categories_names: [],
          text: all_text
        }
    },
    computed: {
        allStopProducts()
        {
          return Object.keys(STOP_PRODUCTS_MAP)
        }
    },
    methods: {
      openSettings() {
        this.settingsDialog = true;
      },
      closeSettings() {
        this.settingsDialog = false;
      },
      closeWithoutChanges() {
        this.selected_component = this.defaultValues['selectedCategories']
        this.selected_stop_products = this.defaultValues['selectedStopProducts']
        this.my_products_only = this.defaultValues['myProductsOnly']

        this.closeSettings()
      },
      closeAndApplySettings() {
        this.updateSettings(this.selected_component,
                            this.selected_stop_products,
                            this.my_products_only)

        this.applyFilters();
        this.closeSettings();
      },
      resetSettings() {
        this.selected_component = []
        this.selected_stop_products = []
        this.my_products_only = false

        this.closeAndApplySettings();
      }
    },
    watch: {
    },
    created() {
      if (this.$store.getters.PRODUCT_TYPES == null) {
        this.$store.dispatch('GET_PRODUCT_TYPES').then((res) => {
          if (res.status == 200) {
            this.categories_names = res.data
          } else {
            this.$store.commit("RAISE_ALERT", 
                                {'message': this.text.common_error_message,
                                  'status': ALERT_TYPE.ERROR})
          }
        }).catch(() => {
            this.$store.commit("RAISE_ALERT", 
                                {'message': this.text.common_error_message,
                                  'status': ALERT_TYPE.ERROR})
        });
      } else {
          this.categories_names = this.$store.getters.PRODUCT_TYPES
      }
    },
}
</script>

<style lang="scss">
.arrow-left {
  margin-right: 10px;

  width: 0; 
  height: 0; 
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent; 
  
  border-right:10px solid var(--v-green-base);; 
}

.setting-back-title {
  color: var(--v-green-base);
}

.setting-title-container {
  height: 100px;
  display: grid;
  grid-template-columns: 25% 50% 25%;
}

.setting-checkbox-group-title {
  grid-column-start: 2;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-self: center;
  color: var(--v-green-base);

}

.setting-checkbox-group-container {
  display: grid;
  grid-template-columns: 10% 80% 10%;
  margin-top: 20px;
  border-radius: 10px;
}

.setting-title-text-container {
  grid-column-start: 2;
  justify-self: center;
  align-self: center;
}

.setting-product-name {
  color: var(--v-green-base);
  position: relative;
  z-index: 2;
}

.setting-product-name-font-size {
  font-size: 26px;
}

.setting-name-icon {
  position: absolute;
  z-index: 1;
  opacity: 0.7;
}

.setting-name-icon-align {
  height: 65px;
  width: 65px;
  margin-left: -21px;
  margin-top: -13px;
}

.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform .2s ease-in-out;
}

.settings-open-dialog-button {
  display: flex !important;
  align-items: center !important;
  justify-self: end !important;
  color: var(--v-orange-base);
}

.settings-checkbox {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.setting-back-button-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
}


// checkbox - show my products
.setting-my-product .v-input--checkbox .v-label {
  color: var(--v-orange-base) !important;
}

.setting-my-product .v-input--checkbox .v-input--selection-controls__ripple {
  color: var(--v-orange-base) !important;
}

.setting-my-product .v-input--checkbox .v-input--selection-controls__input .v-icon {
  color: var(--v-orange-base) !important;
}

.setting-my-product .v-input--checkbox .v-input--selection-controls__input .primary--text {
  color: var(--v-orange-base) !important;
  caret-color: var(--v-orange-base) !important;
}

.setting-group-checkbox {
  background-color: var(--v-green-base) !important;
}

// checkbox - show my products
.setting-group-checkbox .v-input--checkbox .v-label {
  color: var(--v-main_background-base) !important;
}

.setting-group-checkbox .v-input--checkbox .v-input--selection-controls__ripple {
  color: var(--v-main_background-base) !important;
}

.setting-group-checkbox .v-input--checkbox .v-input--selection-controls__input .v-icon {
  color: var(--v-main_background-base) !important;
}

.setting-group-checkbox .v-input--checkbox .v-input--selection-controls__input .primary--text {
  color: var(--v-main_background-base) !important;
  caret-color: var(--v-main_background-base) !important;
}

.setting-group-checkbox .v-messages {
  min-height: 0px !important;
}
.setting-group-checkbox .v-input--selection-controls {
  margin-top: 7px !important;
  margin-bottom: 3px !important;
}

.setting-card-product-button {
  margin-top: 35px;
  margin-bottom: 35px;
  width: 120px;
  height: 35px;
  background-color: var(--v-orange-base);
  color: var(--v-main_background-base);
  border-radius: 10px;
  font-size: 12px;
}

</style>
