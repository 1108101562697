<template>
      <v-container fluid fill-height>
          <h2>Упс, такой страницы не существует</h2>
      </v-container>
</template>

<script>
export default {
   name: 'NotFound',
   data() {
      return {
      }
   },
   methods: {}
}
</script>

<style></style>
