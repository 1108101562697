<template>
  <v-container>
    <template v-if="recipeInfo || $store.getters.LOADING">
    <span class="title-with-shadow-container" style="margin-bottom: 20px;">
      <span class="title-with-shadow">
        <span class="title-with-shadow-main">
          {{ text.recipe.recipe }}
        </span>
        <span class="title-with-shadow-shadow">
          {{ text.recipe.recipe }}
        </span>
      </span>
      <span class="change-icons-container" v-if="recipeInfo.is_owner">
        <span v-if="changingRecipe">
          <button
            @click="saveRecipe()"
            class="recipe-change-button common-button"
            :style="$store.getters.LOADING || products.length == 0 ? 'opacity: 60%': ''"
            :disable='$store.getters.LOADING || products.length == 0'>
            {{ text.save }}
          </button>
          <button
            @click="cancelChanging()"
            class="recipe-change-button common-button"
            :disable='$store.getters.LOADING'>
            {{ text.cancel }}
          </button>
        </span>
        <span v-else>
          <button
            @click="startChanging()"
            class="recipe-change-button common-button"
            :disable='$store.getters.LOADING'>
            {{ text.change }}
          </button>
          <button
            @click="removeRecipeDialog = true"
            class="recipe-change-button common-button"
            :disable='$store.getters.LOADING'>
              {{ text.remove }}
          </button>
        </span>
      </span>
    </span>

    <span>
      <hr class="line" />
        <span class="title-container">
          <img class="img-right" />
          <span class="name-container">
            <span class="product-name-font-size product-name">
              <v-text-field
                  v-if="changingRecipe"
                  ref="recipeName"
                  :error-messages="recipeNameErrMsg == '' ? [] : [recipeNameErrMsg]"
                  class="mt-0"
                  v-model="recipeName"
                  :rules="[rules.required, custom_rules.recipeNameUnique]"
                  :label="text.recipe.recipe_name"
                  style="min-width: 200px; margin-left: 20px;"
                  @click="() => {recipeNameErrMsg = ''}">
              </v-text-field>
              <span v-else ref="recipeName">
                {{ recipeInfo.name }}
              </span>
            </span>
          </span>
        </span>
      <hr class="line" />
    </span>

    <span :class="changingRecipe ? ['disabled', 'content-container'] : 'content-container'">
      <span class="table-container">
        <table style="border: none;">
          <tbody>
            <tr v-for="component in kkallTableContent"
              style="height: 50px;" :key="component.name">
              <td style="text-align: start !important;">
                  <span style="margin-left: 40px; margin-right: 40px;">
                  {{ component.name }}
                </span>
              </td>
              <td style="text-align: center;">
                <span v-if="isKnownKalloriesInfo">
                  {{ component.info ? component.info.toFixed(0) : 0 }} ккал
                </span>
                <v-tooltip top v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <img src="./icons/question.svg"
                      style="margin-left: 20px; margin-right: 20px;"
                      height="30px"
                      width="30px"
                      v-bind="attrs"
                      v-on="on" />
                  </template>
                  <span>
                      {{unknownKalloriesProducts}}
                  </span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </table>
      </span>
      <span :class="changingRecipe ? ['disabled', 'stop-product-container'] : 'stop-product-container'">
        <StopProducts :info="recipeInfo" />
      </span>
    </span>

    <IngredientsTableForRecipe
        :isChanging="changingRecipe"
        :products="products"
        :updateProduct="updateProduct"
        :removeProduct="removeProduct"
        :addProduct="addProduct"/>

    <ProductsProportionTable
        :products="products"
        :saveFinalRecipeProportion="saveFinalRecipeProportion"/>

    <span 
      :class="changingRecipe ? 'disabled' : ''"
      class="recipe-info-tables-container">

      <div class="recipe-wave-container">
        <img class="recipe-wave"/>
      </div>

      <CalcAmountTable
        style="z-index: 5"
        :disabled_by_parent="changingRecipe"
        :products="products"
        :recipeName="recipeInfo.name"
        :amountTable="amountTable"/>

      <RecipePriceTable
        style="z-index: 5"
        :disabled_by_parent="changingRecipe"
        :recipeInfo='recipeInfo'
        :amountTable="amountTable"
        :updateRecipePrice="updateRecipePrice" />
    </span>

    <RemoveRecipeDialog
      :closeDialog="closeRemoveRecipeDialog"
      :dialog="removeRecipeDialog"
      :recipeInfo="recipeInfo"/>

    </template> 
    <template v-else-if="!recipeInfo && !$store.getters.LOADING">
        <h2 style="text-align: center">{{ text.recipe.recipe_not_found }}</h2>
    </template> 
  </v-container>
</template>
<script>
import IngredientsTableForRecipe from './IngredientsTableForRecipe.vue'
import ProductsProportionTable from './ProductsProportionTable.vue'
import CalcAmountTable from './CalcAmountTable.vue'
import { COMPONENT_NAME, KALLORIES, ALERT_TYPE } from './define.js'
import StopProducts from './StopProducts.vue'
import RecipePriceTable from './RecipePriceTable.vue'
import RemoveRecipeDialog from './RemoveRecipeDialog.vue'
import all_text from './ru-text'
import all_rules from './rules'
import saveReceptToDB from './save_recipe_to_db'
export default {
  name: 'Recipe',
  components: {
      StopProducts,
      IngredientsTableForRecipe,
      ProductsProportionTable,
      CalcAmountTable,
      RecipePriceTable,
      RemoveRecipeDialog
  },
  data() {
    return {
      recipeInfo: {},
      recipeName: '',
      products: [],
      finalRecipeProportion: {},
      kkallTableContent: [],
      amountTable: [],

      isKnownKalloriesInfo: true,
      unknownKalloriesProducts: '',

      recipeNameErrMsg: '',

      changingRecipe: false,

      headers: [
          { text: '', align: 'start', value: "name"},
          { text: '', value: "value" },
      ],

      removeRecipeDialog: false,

      text: all_text,
      rules: all_rules,
      custom_rules: {
          recipeNameUnique: value => {
            if (value == this.recipeInfo.name) {
              return true;
            }
            let recipes = this.$store.getters.RECIPES != null ? this.$store.getters.RECIPES : [];
            let temp = value.trim()
            return !!recipes.every(info => info.name != temp) || all_text.not_unique_name
          }
      },
    }
  },
  watch: {
    finalRecipeProportion() {
      if (!this.changingRecipe) {
          return
      }
      if (this.products.length == 0) {
        let kkallTableContent = []
        Object.keys(KALLORIES).forEach((component) => {
            kkallTableContent.push({'name': KALLORIES[component], 'value': '-'})
        })
        this.kkallTableContent = kkallTableContent
        this.isKnownKalloriesInfo = false
        this.unknownKalloriesProducts = this.text.no_product
        return
      }
      let calloriesInfo = {}
      let kkallTableContent = []
      this.isKnownKalloriesInfo = true
      this.unknownKalloriesProducts = ''
      Object.keys(KALLORIES).forEach((component) => {
          calloriesInfo[component] = 0
          this.products.forEach((item) => {
              if (item.name != this.text.total) {
                  calloriesInfo[component] += item[component]
              }
          }, calloriesInfo, component)
      }, calloriesInfo)
  
      this.getProductWithoutKKall()

      Object.keys(KALLORIES).forEach((component) => {
          let val = calloriesInfo[component] * 100 / this.products.at(-1).amount
          if (this.isKnownKalloriesInfo) {
              kkallTableContent.push({'name': KALLORIES[component], 'value': val.toFixed(0)})
          } else {
              kkallTableContent.push({'name': KALLORIES[component], 'value': '-'})
          }
      })
      this.kkallTableContent = kkallTableContent
    },
    changingRecipe(newVal) {
      if (!newVal) {
          return
      }
      if (this.$store.getters.PRODUCTS == null) {
          this.$store.dispatch('GET_PRODUCTS').then((res) => {
              if (res.status != 200) {
                  this.$store.commit("RAISE_ALERT",
                                      {'message': this.text.common_error_message,
                                      'status': ALERT_TYPE.ERROR})
              }
          });
      }
      if (this.$store.getters.RECIPES == null) {
          this.$store.dispatch('GET_RECIPES').then((res) => {
          if (res.status != 200) {
              this.$store.commit("RAISE_ALERT",
                                  {'message': this.text.common_error_message,
                                  'status': ALERT_TYPE.ERROR})
          }
          });
      }
    }
  },
  created() {
    this.$store.dispatch('GET_RECIPE', this.$route.params.id).then((res) => {
      if (res.status == 200) {
          this.recipeInfo = res.data
          this.recipeName = this.recipeInfo.name
          this.collectProducts()
          this.setkkallTableContent()
      }
    }).catch((err) => {
      if(err.response.status == 400 && err.response.data.message.includes("Recipe is not exists")) {
        this.recipeInfo = null
        this.$store.commit("RAISE_ALERT",
                            {'message': this.text.recipe_is_not_exists,
                            'status': ALERT_TYPE.ERROR})
      } else {
        this.$store.commit("RAISE_ALERT",
                            {'message': this.text.common_error_message,
                            'status': ALERT_TYPE.ERROR})
      }
    })
  },
  methods: {
    updateProduct(idx, updated_item, key=null) {
      if (key) {
        if (key >= 0) {
          this.products[idx][key] = updated_item
        } else {
          this.products.at(idx)[key] = updated_item
        }
      } else {
        if (key >= 0) {
          this.products[idx] = updated_item
        }
      }
    },
    addProduct(new_item, before_idx=null) {
      if (before_idx) {
        this.products.splice(before_idx, 0, new_item);
      } else {
        this.products.push(new_item);
      }
    },
    removeProduct(idx) {
      this.products.splice(idx, 1);
    },
    saveRecipe(){
      let results = saveReceptToDB(false, this.recipeName,
                                    this.products, this.finalRecipeProportion,
                                    this.$route.params.id)
      results.then((res) => {
        if (res.otherError) {
          this.$store.commit("RAISE_ALERT",
            {'message': res.otherError,
            'status': ALERT_TYPE.ERROR})
        } else if (res.recipeNameErr) {
          this.recipeNameErrMsg = res.data.recipeNameErr
          // const el = this.$refs.recipeName
          // if (el) {
          //   el.scrollIntoView({behavior: "smooth"});
          // }
        } else if (res.status == 200) {
          this.recipeInfo = res.data.recipe
          this.recipeName = this.recipeInfo.name
          this.collectProducts()
          this.setkkallTableContent()
          this.changingRecipe = false
        } else {
          this.$store.commit("RAISE_ALERT",
          {'message': res.otherError,
          'status': ALERT_TYPE.ERROR})
        }
      }).catch(() => {
        this.$store.commit("RAISE_ALERT",
          {'message': this.text.common_error_message,
          'status': ALERT_TYPE.ERROR})
      })
    },
    getProductWithoutKKall() {
        let unknownKalloriesProductsList = []
        this.products.forEach((item) => {
            if (item.name != this.text.total && item['kilokall'] == 0) {    
                this.isKnownKalloriesInfo = false                    
                if (unknownKalloriesProductsList.indexOf(item.name) == -1) {
                    unknownKalloriesProductsList.push(item.name)
                }
            }
        })

        if (unknownKalloriesProductsList.length > 0) {
            this.unknownKalloriesProducts = 'Для продуктов: "' + unknownKalloriesProductsList.join('", "') + '"\
                                        не установлены каллории '
        }
    },
    setkkallTableContent() {
        let content = []
        if (this.recipeInfo != null &&
            (this.recipeInfo.fats > 0 ||
            this.recipeInfo.proteins > 0 ||
            this.recipeInfo.carbohydrates > 0 ||
            this.recipeInfo.kilokall > 0)) {
            Object.entries(KALLORIES).forEach(([component, name]) => {
                content.push({'name': name, 'value': this.recipeInfo[component]})
            })
        } else {
            Object.entries(KALLORIES).forEach(([, name]) => {
              content.push({'name': name, 'value': '-'})
            })
            this.getProductWithoutKKall()
        }

        this.kkallTableContent = content
    },
    closeRemoveRecipeDialog(isRemoved) {
        if (isRemoved) {
            this.$router.push('/recipes')
        }
        this.removeRecipeDialog = false
    },
    saveFinalRecipeProportion(finalProportion) {
      let finalRecipeProportion = {}
      Object.keys(COMPONENT_NAME).forEach((component) => {
          finalRecipeProportion[component] = finalProportion[component]
      })
      this.finalRecipeProportion = finalRecipeProportion
    },
    startChanging() {
      if (this.$store.getters.RECIPES == null) {
        this.$store.dispatch('GET_RECIPES').then((res) => {
          if (res.status == 200) {
            this.$store.dispatch('GET_PRODUCTS').then((res) => {
              if (res.status == 200) {
                  this.changingRecipe = true;
              }
            });
          }
        });
      } else if (this.$store.getters.PRODUCTS == null) {
          this.$store.dispatch('GET_PRODUCTS').then((res) => {
              if (res.status == 200) {
                this.changingRecipe = true;
              }
          });
      } else {
        this.changingRecipe = true
      }

    },
    cancelChanging() {
      this.changingRecipe = false
      this.recipeName = this.recipeInfo.name
      this.collectProducts()
      this.setkkallTableContent()
    },

        setupRecipeErrMsg(errMsg) {
            this.recipeNameErrMsg = errMsg
        },
        collectProducts() {
            let prs = [];
            if (this.recipeInfo != null && this.recipeInfo.products) {
                let sumAmount = 0
                this.recipeInfo.products.forEach(element => {
                    let newElement = JSON.parse(JSON.stringify(element))
                    Object.keys(COMPONENT_NAME).forEach((component) => {
                        newElement[component] = newElement[component].toFixed(2)
                    })
                    prs.push(newElement)
                    sumAmount += element.amount
                });
                prs.push({'name': this.text.total, 'amount': sumAmount})
            }
            this.products = prs
        },
        updateRecipePrice(price, weight, index) {
            this.recipeInfo.products[index]['price'] = price
            this.recipeInfo.products[index]['price_weight'] = weight
        }
    }
}


</script>

<style>
@import './common.scss';

.recipe-change-button {
  margin-top: 20px;
  width: 100px;
  height: 35px;
  font-size: 16px;
  margin-left: 20px;
}

th, td {
  border-bottom: 3px solid var(--v-green-base);
}

tr:hover {
  background-color: var(--v-main_background2-base);
}

.line {
  position: absolute;
  width: 100%;
  left: 50%;
  margin-left: -50%;
  height: 2px; /* or whatever. */
  background-color: var(--v-green-base);
}

.title-container {
  height: 102px;
  display: grid;
  grid-template-columns: 25% 50% 25%;
}

.name-container {
  grid-column-start: 2;
  justify-self: center;
  align-self: center;
}

.product-name {
  color: var(--v-green-base);
  position: relative;
  z-index: 2;
}

.product-name-font-size {
  font-size: 36px;
}

.img-right {
  content: url("./icons/pattern_right.svg");
  position: absolute;
  right: 50%;
  margin-right: -50%;
}

.content-container {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.stop-product-container {}

.table-container {
  margin-right: 40px;
  margin-top: 20px;
}

.change-price-btn-size {
  width: 120px;
  height: 70px;
  font-size: 16px;
}

.disabled {
  opacity: 0.5;
}

.recipe-wave {
  content: url("./icons/wave_6.svg");
  width: 110%;
}

.recipe-wave-container {
  overflow: hidden;
  height: -webkit-fill-available;
  width: 150%;
  position: absolute;
  margin-top: -30px;
  z-index: 1;
  opacity: 0.7;
  margin-right: -75%;
  right: 50%;
}

.recipe-info-tables-container {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 80px;
  margin-bottom: 250px;
}

@media (max-width: 950px) {

  .recipe-wave-container {
    width: 300%;
    margin-right: -100%;
  }

  .recipe-wave {
    width: 130%;
  }

  .recipe-info-tables-container {
    display: grid;
    grid-template-columns: 100%;
    margin-bottom: 100px;
  }

  .title-container {
    height: 80px;
    display: grid;
    grid-template-columns: 25% 50% 25%;
  }

  .change-icons-container {
    align-self: auto;
    justify-self: auto;
  }

  .recipe-change-button {
    margin-top: 20px;
    width: 80px;
    height: 30px;
    font-size: 13px;
    margin-left: 5px;
  }

  .product-name-font-size {
    font-size: 20px;
  }

  .img-right {
    content: url("./icons/pattern-right-small.svg");
    position: absolute;
    right: 50%;
    margin-right: -50%;
  }

  .content-container {
    display: grid;
    grid-template-columns: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .stop-product-container {
    grid-row: 1;
  }

  .table-container {
    margin: auto;
    margin-top: 20px;
  }


}
</style>
