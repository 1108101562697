<template>
  <v-container class="recipe-price-border">
    <div class="recipe-price-button-container" v-if="!showTable">
      <button
        :class="!recipeInfo.products || recipeInfo.products.length == 0 || disabled_by_parent || $store.getters.LOADING ? 
                ['common-disable-text-color common-button recipe-price-button'] : 
                ['common-button recipe-price-button']"
        @click="() => {showTable = true; priceTableCalc()}"
        :disabled="!recipeInfo.products || recipeInfo.products.length == 0 || disabled_by_parent || $store.getters.LOADING">
        {{ this.text.recipe.calculate_price }}
      </button>
      <div class="recipe-price-star">
        <img class="recipe-price-star-icon" src="./icons/star_big.svg" />
      </div>
    </div>

    <div v-else class="recipe-price-table-container">
      <div class="recipe-price-header">
        {{ this.text.recipe.calculate_price }}
      </div>
      <div style="width: 90%; z-index: 10; position: absolute;">
        <v-form v-if="change_price_product != null" 
          ref="price" v-model="valid_price" validate-on="submit input">
          <v-card 
            color="main_background"
            elevation="0">
            <v-card-text style="display: flex">
              <v-text-field
                style="margin-right: 10px"
                v-model="price"
                :rules="[rules.required, rules.double]"
                :label="text.recipe.price">
              </v-text-field>
              <v-text-field
                v-model="weight"
                :rules="[rules.required, rules.double]"
                :label="text.recipe.weight">
              </v-text-field>
            </v-card-text>
            <v-card-actions>
              <button
                  :disabled="!valid_price"
                  :class="!valid_price ?
                        ['common-disable-text-color', 'common-button'] :
                        ['common-button']"
                  style="padding: 2px 10px; width: 110px; margin-right: 10px"
                  @click="savePrice()" >
                  {{ text.save }}
              </button>
              <button
                  class='common-button'
                  style="padding: 2px 10px; width: 110px;"
                  @click="finishPriceChange()" >
                  {{ text.cancel }}
              </button>
            </v-card-actions>
          </v-card>
        </v-form>
      </div>
      <table class="recipe-price-table">
        <thead>
          <tr :class="change_price_product != null ? 'disabled-row common-disable-text-color' : 'active-row'">
            <th>{{ text.recipe.product }}</th>
            <th style="text-align: start !important; padding-left: 20px">{{ text.recipe.amount_gramm_in_candy }}</th>
            <th style="text-align: start !important; padding-left: 20px">{{ text.recipe.price }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in priceTable"
            :key="item.name + '_price'"
            :class="change_price_product != null ? 'disabled-row common-disable-text-color' : 'active-row'">
            <td :class="item.name == text.total ? 'recipe-price-table-none-border' : 'recipe-price-table-border'">
              {{ item.name}}
            </td>
            <td
              :class="item.name == text.total ? 'recipe-price-table-none-border' : 'recipe-price-table-border'"
              style="text-align: start !important; padding-left: 20px">
              {{ item.amount }}
            </td>
            <td
              :class="item.name == text.total ? 'recipe-price-table-none-border' : 'recipe-price-table-border'"
              style="text-align: start !important; padding-left: 20px">
              {{ item.price }}
            </td>
            <td :class="item.name == text.total ? 'recipe-price-table-none-border' : 'recipe-price-table-border'" style="min-width: 90px;">
              <button
                v-if="item.name != text.total"
                :class="change_price_product != null ? 'common-disable-text-color' : ''"
                @click="() => {
                    change_price_product = item.id;
                    change_price_product_index = index }" 
                :disabled="$store.getters.LOADING || change_price_product != null">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <img
                        src="./icons/product_edit.svg"
                        height="30px"
                        width="30px"
                        v-bind="attrs"
                        v-on="on" />
                  </template>
                  <span>{{ text.product.change_price }}</span>
                </v-tooltip>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </v-container>
</template>
<script>
import all_text from './ru-text'
import { ALERT_TYPE } from './define.js'
import all_rules from './rules'
export default {
  props: {
      recipeInfo: {
          Object
      },
      amountTable: {
          type: Array
      },
      updateRecipePrice: {
          type: Function,
      },
      disabled_by_parent: { type: Boolean }
  },
  name: 'RecipePriceTable',
  components: {
  },
  data() {
    return {
      change_price_product: null,
      change_price_product_index: null,

      weight: undefined,
      price: undefined, 
      valid_price: false,

      priceTable: [],
      showTable: false,

      text: all_text,
      rules: all_rules
    }
  },
  watch: {
    amountTable() {
      this.priceTableCalc();
      this.finishPriceChange();
    },
    disabled_by_parent(newVal) {
      if (newVal == true) {
        this.finishPriceChange();
        this.priceTable = [];
        this.showTable = false;
      }
    }
  },
  methods: {
    finishPriceChange() {
      this.change_price_product = null;
      this.change_price_product_index = null
      this.price = undefined
      this.weight = undefined
    },
    savePrice() {
      this.$store.dispatch('UPDATE_PRICE', {'product_id': this.change_price_product,
                                            'price': this.price,
                                            'weight': this.weight}).then((res) => {
        if (res.status == 200) {
          this.updateRecipePrice(this.price, this.weight, this.change_price_product_index)
          this.priceTableCalc()
        } else {
          this.$store.commit("RAISE_ALERT",
                            {'message': this.text.common_error_message + '4234',
                              'status': ALERT_TYPE.ERROR})
        }
        this.finishPriceChange()
      }).catch(() => {
          this.$store.commit("RAISE_ALERT",
                                  {'message': this.text.common_error_message + '777',
                                    'status': ALERT_TYPE.ERROR})
        this.finishPriceChange()
      });
    },
    priceTableCalc() {
      let table = []
      let total_price = 0
      let total_amount = 0
      if (this.recipeInfo && this.recipeInfo.products) {
        table = this.recipeInfo.products.map(function(product, i) {
          let newItem = {}
          newItem['name'] = product['name']
          newItem['id'] = product.id

          let amount = !this.amountTable || this.amountTable.length == 0 ?
                          parseFloat(product['amount']) :
                          parseFloat(this.amountTable[i].customWeight)
            newItem['amount'] = amount
            let price = product['price'] == undefined || product['price'] == -1 ? 0 : product['price']
            newItem['price'] = price * amount / 100
            if (newItem['name'] != this.text.total) {
                total_price += newItem['price']
                total_amount += newItem['amount']
            }
            newItem['price'] = newItem['price'].toFixed(2)
            return newItem
        }.bind(this), total_price, total_amount);
        table.push({'name': this.text.total, 'amount': total_amount, 'price': total_price.toFixed(2)})
      }

      this.priceTable = table
  },
  }
}
</script>
<style>

@import './common.scss';

.recipe-price-table table {
  border-collapse: collapse;
  width: 100%;
  border: none !important;
}

.recipe-price-table th {
  height: 60px;
}

.recipe-price-table td {
  height: 50px;
}

.recipe-price-table th, .recipe-price-table td {
  text-align: center;
}

.recipe-price-table-border {
  border-bottom: 1px solid var(--v-green-base) !important;
}

.recipe-price-table-none-border {
  border-bottom: none !important;
}

.active-row:hover {
  background-color: var(--v-main_background2-base);
}

.disabled-row:hover {
  background-color: var(--v-main_background-base);
}

.recipe-price-table-container {
  position: relative;
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.recipe-price-header {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: var(--v-main_background-base);
  font-size: 16px;
  height: 46px;
  background-color: var(--v-green-base);
  border-radius: 0px 6px 0px 0px;
}

.recipe-price-star-icon {
  align-self: center;
  width: 30px;
  height: 30px;
}

.recipe-price-star {
  display: flex;
  justify-content: center;
  width: 40px;
  border-radius: 20px 6px 6px 20px;
  background-color: var(--v-green-base);
  border-color: var(--v-green-base);
  border-style: solid;
  justify-self: end;
}

.recipe-price-button {
  margin: 5px 0px 5px 10px;
  height: 35px;
  font-size: 14px;
  justify-self: start;
  width: 85%;
}

.recipe-price-border {
  height: fit-content;
  padding: 0px !important;
  border-style: solid solid solid solid;
  border-color: var(--v-green-base);
  border-radius: 0px 10px 10px 0px;
}

.recipe-price-button-container {
  display: grid;
  grid-template-columns: auto auto;
}

@media (max-width: 950px) {

  .recipe-price-header {
    border-radius: 6px 6px 0px 0px;
  }

  .recipe-price-border {
    border-radius: 10px 10px 10px 10px;
  }

  .recipe-price-border {
    margin-top: 20px;
  }
}

</style>
