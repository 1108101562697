import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import './main.scss';

Vue.use(Vuetify)

const opts = {
    icons: {
        iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    theme: {
      options: {
        customProperties: true,
      },
        themes: {
          light: {
            primary: '#5C704B',
            secondary: '#0094BA', // #FFCDD2
            check_box: '#008BCB', // #FFCDD2
            loading: '#00527F', // #FFCDD2?
            success: '#5C704B',
            error: '#6F2B21',
            form_toolbar: '#005483',
            // accent: colors.indigo.base, // #3F51B5

            green: "#5C704B",
            main_background: "#E9E4DE",
            main_background2: "#BFC3BC",
            orange: "#A47E5A",
            almost_white: "#313D27",

            red: '#6F2B21'
          },
          dark: {
            background: "#E9E4DE"
          }
        },
    }
}

export default new Vuetify(opts)