<template>
  <v-dialog
      persistent
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.width < mobile_border"
      scrollable
      max-width="70%">
    <v-card color="main_background">
      <v-card-text>
        <v-form ref="product" v-model="valid">
          <div v-if="categories && categories.length > 1"
            class='pt-6 two-element-in-row'>
            <v-select
                class="pr-3 pl-3"
                :items="categories"
                v-model="draft_productItem['product_type']" 
                :label="text.category"
                :rules="[rules.required]"
            ></v-select>
          </div>
          <div :class="$vuetify.breakpoint.width > mobile_border ? 'two-element-in-row' : ''"
            :style="categories && categories.length > 1 ? '' : 'padding-top: 20px'">
            <v-select
                class="mt-0 pl-3 pr-3"
                :rules="[rules.required]"
                :items="products"
                v-model="draft_productItem['name']"
                :label="text.recipe.product">
            </v-select>
            <v-text-field
                class="mt-0 pl-3 pr-3"
                readonly
                v-model="draft_productItem['firm']"
                label="Фирма">
            </v-text-field>
          </div>
          <div :class="$vuetify.breakpoint.width > mobile_border ? 'three-element-in-row' : ''">
            <div
              v-for="(name, label) in componentsName"
              :key="name + '_new_product'"
              class="pt-0 pb-0 pr-3 pl-3">
              <v-text-field
                  :label="name"
                  readonly
                  :disabled="draft_productItem[label] == undefined"
                  v-model="draft_productItem[label]"
                  suffix="%">
              </v-text-field>
            </div>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <button
          :disabled="!valid"
          :style="valid ? '' : 'opacity: 60%'"
          class="common-button new-ingredients-button"
          @click="checkAndCloseDialog()">
          {{ text.save }}
        </button>
        <button
          class="common-button new-ingredients-button"
          @click="closeDialog(false, {})">
          {{ text.cancel }}
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { COMPONENT_NAME, MOBILE_BORDER } from './define.js'
import all_text from './ru-text'
import all_rules from './rules'
export default {
  props: {
    dialog: {
      type: Boolean,
    },
    closeDialog: {
      type: Function
    },
    categories: {
        type: Array
    },
    allowded_component: {
        type: String,
        default: undefined
    },
    prohibited_products: {
        type: Array,
        default: () => []
    },
  },
  name: 'NewCleverIngredient',
  data() {
    return {
      mobile_border: MOBILE_BORDER,
      componentsName: COMPONENT_NAME,
      valid: false,

      draft_productItem: [],

      products: [],

      existingProductInfo: [],

      text: all_text,
      rules: all_rules
    }
  },
  created() {
    this.existingProductInfo = this.$store.getters.PRODUCTS;

    if (this.existingProductInfo) {
      if (this.categories) {
        this.categories.forEach((product_type, ) => {
          if (this.existingProductInfo[product_type]) {
            Object.entries(this.existingProductInfo[product_type]).forEach(([, info]) => {
              // 1. not in prohibited products
              // 2. allow_components exists and above 0 in components of product
              // 3. products created by programmer
              if (this.prohibited_products.indexOf(info.name) == -1 &&
                  (!this.allowded_component || info[this.allowded_component] > 0) 
                  // && info.is_owner == false  -  allow custom products or not ? 
                  ) {
                this.products.push(info.name);
              }
            })
          }
        })
      }
      else {
        Object.keys(this.existingProductInfo).forEach((product_type, ) => {
          Object.entries(this.existingProductInfo[product_type]).forEach(([, info]) => {
            if (this.prohibited_products.indexOf(info.name) == -1 &&
               (!this.allowded_component || info[this.allowded_component] > 0)) {
              this.products.push(info.name);
            }
          })
        })
      }
    }
  },
  watch: {
    'draft_productItem.product_type': function(newVal, oldVal) {
      let newProducts = []
      if (this.existingProductInfo[newVal]) {
        Object.entries(this.existingProductInfo[newVal]).forEach(([, info]) => {
          if (this.prohibited_products.indexOf(info.name) == -1 &&
            (!this.allowded_component || info[this.allowded_component] > 0)) {
            newProducts.push(info.name);
          }
        })
      }
      this.products = newProducts;
      if (oldVal != undefined) {
        this.draft_productItem['name'] = undefined
      }
    },
    'draft_productItem.name': function(newVal) {
      if (newVal == undefined) {
        Object.keys(this.draft_productItem).forEach(([key, ]) => {
          this.draft_productItem[key] = undefined
        });
      } else {
        // 0 - choose from existing products
        if (!this.draft_productItem['product_type']) {
          let real_product_type = undefined
          Object.entries(this.existingProductInfo).some(([product_type, products_info]) => {
            products_info.some((info, ) => {
              real_product_type = (newVal == info.name) ? product_type : undefined
              return real_product_type
            })
            return real_product_type
          })
          this.draft_productItem['product_type'] = real_product_type
        } 
        let product_type = this.draft_productItem['product_type'];
        let temp;
        for (let product of this.existingProductInfo[product_type]) {
          if (product.name == newVal) {
            temp = product
            break
          }
        }
        Object.entries(temp).forEach(([component, ]) => {
          this.draft_productItem[component] = component in COMPONENT_NAME ? 
                                                  temp[component].toFixed(2) :
                                                  temp[component]
        })
      }
    }
  },
  methods: {
    checkAndCloseDialog() {
      this.closeDialog(true, this.draft_productItem)
    }
  }
}
</script>
<style>
</style>
