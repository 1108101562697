<template>
  <v-container>
    <span class="main-title-container-products">
      <span class="title-product">
        <span class="product-title">
          {{ main_title }}
        </span>
        <span class="product-shadow">
          {{ main_title }}
        </span>
      </span>
      <span class="search-container">
        <v-text-field
          :disabled="$store.getters.LOADING"
          @input="updateSearch(search)"
          v-model="search"
          :label="text.search"
          single-line
          hide-details
          rounded
          solo>
        <template v-slot:append>        
          <v-icon class="search"> mdi-magnify </v-icon> 
        </template>
      </v-text-field>
      </span>
    </span>

    <span class="settings-container">
      <button @click="onCreateNew()" class="products-create-new-button">
        {{ createNewTitle }}
        <img
          style="margin-left: 10px"
          src="./icons/add_new_product.svg"
          height="30px"
          width="30px" />
      </button>

      <SearchSettings
        v-if="$vuetify.breakpoint.width < mobile_border"

        style="grid-column-start: 2; display: flex; justify-content: end;"
        :applyFilters="applyFilters"
        :updateSettings="updateSettings"
        :categoriesOn="updateSelectedCategories != undefined"
        :defaultValues="{'myProductsOnly': myProductsOnly, 
                         'selectedCategories': selectedCategories, 
                         'selectedStopProducts': selectedStopProducts}"
         />
  
      <span
        v-if="$vuetify.breakpoint.width >= mobile_border"

        style="padding-left: 30px; padding-right: 30px;"
        class="products-selector">
        <v-select
          v-if="updateSelectedCategories"
          @change="updateSelectedCategoriesAndUpdateFilters()"
          v-model="selectedCategories"
          :items="allCategories"
          multiple
          :label="text.categories">
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 2">
                <span>{{ item }}</span>
            </v-chip>
            <span
                v-if="index == 2"
                class="grey--text text-caption">
                (+ {{text.others}} {{ selectedCategories.length - 2 }})
            </span>
          </template>
        </v-select>
        <v-select
          v-if="updateSelectedStopProducts"
          @change="updateSelectedStopProductsAndUpdateFilters()"
          v-model="selectedStopProducts"
          :items="allStopProducts"
          multiple
          :label="text.stop_products">
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 2">
              <span>{{ item }}</span>
            </v-chip>
              <span
                v-if="index == 2"
                class="grey--text text-caption">
                (+ {{text.others}} {{ selectedStopProducts.length - 2 }} )
              </span>
          </template>
        </v-select>
      </span>

      <span class="my-product products-create-new-button">
        <v-checkbox
          v-if="$vuetify.breakpoint.width >= mobile_border"
          v-model="myProductsOnly"
          :label="titleForOwners"
          @change="setCheckboxAndUpdateFilters()">
        </v-checkbox>
      </span>
    </span>
  </v-container>
</template>

<script>
import SearchSettings from './SearchSettings'
import {STOP_PRODUCTS_MAP, COMPONENT_NAME, MOBILE_BORDER} from './define'
import all_text from './ru-text'

import './font/Lato/Lato-Regular.ttf'

export default {
    name: 'Products',
    props: {
      main_title: {
        String
      },
      updateSearch: {
        Function
      },
      createNewTitle: {
        String
      },
      onCreateNew: {
        Function
      },
      updateSelectedCategories: {
        Function
      },
      updateSelectedStopProducts: {
        Function
      },
      setCheckbox: {
        Function
      },
      applyFilters: {
        Function
      },
      titleForOwners: {
        String
      } 
    },
    components: {
      SearchSettings
    },
    data() {
        return {
          search: '',
          selectedCategories: [],
          allCategories: [],

          selectedStopProducts: [],
          myProductsOnly: false,

          component_names: COMPONENT_NAME,
          text: all_text,
          mobile_border: MOBILE_BORDER
        }
    },
    computed: {
      allStopProducts()
      {
        return Object.keys(STOP_PRODUCTS_MAP)
      }
    },
    methods: {
      updateSettings(selectedCategories, selectedStopProducts, myProductsOnly) {
        this.selectedCategories = selectedCategories
        this.selectedStopProducts = selectedStopProducts
        this.myProductsOnly = myProductsOnly
        if (this.updateSelectedCategories != undefined) {
          this.updateSelectedCategories(this.selectedCategories)
        }
        this.updateSelectedStopProducts(this.selectedStopProducts)
        this.setCheckbox(this.myProductsOnly)
      },

      updateSelectedCategoriesAndUpdateFilters() {
        this.updateSelectedCategories(this.selectedCategories)
        this.applyFilters()
      },

      updateSelectedStopProductsAndUpdateFilters() {
        this.updateSelectedStopProducts(this.selectedStopProducts)
        this.applyFilters()
      },
      setCheckboxAndUpdateFilters() {
        this.setCheckbox(this.myProductsOnly)
        this.applyFilters()
      }
    },
    watch: {
    },
    created() {
      this.allProducts = this.$store.getters.PRODUCTS
      this.products = this.$store.getters.PRODUCTS

      if (this.$store.getters.PRODUCTS == null) {
        this.$store.dispatch('GET_PRODUCTS').then((res) => {
            if (res.status == 200) {
                this.allProducts = this.$store.getters.PRODUCTS
                this.products = this.$store.getters.PRODUCTS
            } else {
              this.no_products_err_msg = this.text.common_error_message
            }
        }).catch(() => {
          this.no_products_err_msg = this.text.no_connection_error_message
        });
      }
    
      if (this.$store.getters.PRODUCT_TYPES == null) {
        this.$store.dispatch('GET_PRODUCT_TYPES').then((res) => {
          if (res.status == 200) {
              this.allCategories = res.data
          }
        });
      } else {
        this.allCategories = this.$store.getters.PRODUCT_TYPES
      }
    },
}
</script>

<style lang="scss">
@import './common.scss';

.main-title-container-products {
  display: grid;
  grid-template-columns: 40% 20% 40%;
}

.title-product {
  font-size: 64px;
  color: var(--v-green-base);
  margin-top: 20px;
  margin-bottom: 20px;
}

.product-title {
  position: absolute;
  padding-top: 10px;
}

.product-shadow {
  margin-left: 80px;
  opacity: 0.3;
}

.search-container {
  align-self: center;
  margin-top: 30px;
  grid-column-start: 3;
}

.products-create-new-button {
  display: flex !important;
  align-items: center !important;
  color: var(--v-orange-base);
}

// checkbox - show my products
.my-product .v-input--checkbox .v-label {
  color: var(--v-orange-base) !important;
}

.my-product>.v-input--checkbox .v-input--selection-controls__ripple {
  color: var(--v-orange-base) !important;
}

.my-product>.v-input--checkbox .v-input--selection-controls__input .v-icon {
  color: var(--v-orange-base) !important;
}

.my-product>.v-input--checkbox .v-input--selection-controls__input .primary--text {
  color: var(--v-orange-base) !important;
  caret-color: var(--v-orange-base) !important;
}

// selector label
.products-selector .v-text-field .v-select__slot .v-label {
  color: var(--v-green-base) !important;
}

// selector icon, arrow
.products-selector>.v-text-field .v-select__slot .v-icon {
  color: var(--v-green-base) !important;
}

.products-selector>.v-text-field .v-input__control .v-input__slot:before  {
  color: var(--v-green-base) !important;
}

.v-menu__content .v-list-item__action:first-child {
  margin-right: 10px !important;
  color: var(--v-main_background-base) !important;
  caret-color: var(--v-main_background-base) !important;
}

.v-menu__content .v-list-item__action .v-icon {
  color: var(--v-main_background-base) !important;
  caret-color: var(--v-main_background-base) !important;
}

.v-menu__content .v-list {
  color: var(--v-main_background-base) !important;
  background-color: var(--v-green-base) !important;
}

.v-menu__content .v-list-item__title {
  color: var(--v-main_background-base) !important;
}

.v-text-field .v-input__control .v-input__slot:before {
  border-color: var(--v-green-base) !important;
}

.v-select__slot .v-chip {
  color: var(--v-main_background-base) !important;
  background-color: var(--v-green-base) !important;
}

// search-container
.search-container .v-text-field--rounded {
  border-style: solid;
  border-color: var(--v-green-base) !important;
}

.search-container .v-icon {
  color: var(--v-green-base) !important;
}

.search-container .v-text-field.v-text-field--solo .v-input__control input {
  color: var(--v-green-base) !important;
  caret-color: var(--v-green-base) !important;
}

.search-container .v-text-field.v-text-field--solo .v-label {
  color: var(--v-green-base) !important;
}

.search-container .v-text-field--solo>.v-input__control>.v-input__slot {
  background-color: var(--v-main_background-base) !important;
}

.settings-container {
  display: grid;
  grid-template-columns: 25% 50% 25%
}

@media (max-width: 950px) {
  .main-title-container-products {
    display: grid;
    grid-template-columns: 100%;
  }

  .title-product {
    font-size: 32px;
    color: var(--v-green-base);
    margin-top: 20px;
    margin-bottom: 20px;
    justify-self: center;
  }

  .product-shadow {
    margin-left: 40px;
    opacity: 0.3;
  }

  .search-container {
    align-self: center;
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
    grid-column-start: 1;
  }

  .settings-container {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 20px
  }
}
</style>
