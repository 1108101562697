import store from './calc_store'
import all_text from './ru-text'
import { COMPONENT_NAME} from './define.js'

async function saveReceptToDB(isCreate, recipeName, recipeProducts, changedRecipeProportion, recipeID=null) {
  let status = 0
  let recipeNameErr = null
  let otherError = null
  let newRecipe = null

// -------------- check name -----------------
  if (!recipeName || recipeName == "") {
    return {'status': -1,
            'recipeNameErr': all_text.recipe.put_name,
            'otherError': null,
            'newRecipe': newRecipe}
  }

// -------------- check that name is unique -----------------
  if (isCreate) {
    let dbRecipes = store.getters.RECIPES || []
    let nameUnique = dbRecipes.every(info => info.name != recipeName)
    if (!nameUnique) {
      recipeNameErr = all_text.recipe.change_name
    }

    if (status < 0) {
      return {'status': status,
              'recipeNameErr': recipeNameErr,
              'otherError': otherError,
              'newRecipe': newRecipe}
    }
  }

// -------------- check all products are saved to db -----------------
  let inDBProducts = []
  if (store.getters.PRODUCTS) {
    Object.entries(store.getters.PRODUCTS).forEach(([, dbProductsByCategory]) => {
      inDBProducts.push(...dbProductsByCategory.map(product => product.name))
    }, inDBProducts)
  }

  let data = JSON.parse(JSON.stringify(recipeProducts))
  let notDBProducts = data.filter(info => (info.name != all_text.total &&
                                           inDBProducts.indexOf(info.name) == -1))
  if (notDBProducts.length != 0) {
    let alertInfo = notDBProducts.length == 1 ? all_text.one_product : all_text.products
    alertInfo += notDBProducts.map(item => item.name).join(', ')
    alertInfo += notDBProducts.length == 1 ? all_text.not_saved_one : all_text.not_saved_several
    alertInfo += notDBProducts.length == 1 ? all_text.saved_it_before_recipe : all_text.saved_them_before_recipe 
    status = -1
    otherError = alertInfo
  }

  if (status < 0) {
    return {'status': status,
            'recipeNameErr': recipeNameErr,
            'otherError': otherError,
            'newRecipe': newRecipe}
  }

  // -------------- fill Всего  -----------------
  Object.keys(COMPONENT_NAME).forEach((component) => {
      data.at(-1)[component] = changedRecipeProportion[component]
  }, data, changedRecipeProportion);

  // -------------- fill request  -----------------
  let actionName = isCreate ? 'ADD_RECIPE' : 'UPDATE_RECIPE'
  let payload = { 'products': data, 'name': recipeName }
  if (!isCreate) {
      payload['id'] = recipeID
  }

  return store.dispatch(actionName, payload)
}

export default saveReceptToDB