<template>
  <v-container>
    <v-dialog
      v-model="change_dialog"
      persistent
      :fullscreen="$vuetify.breakpoint.width < mobile_border"
      max-width="50%">
      <v-card class="elevation-12" color="main_background">
        <v-toolbar color="green">
          <v-toolbar-title>
            <span class="user-dialog-title">
              {{ text.change_form }}
            </span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-5">
          <v-form v-model="valid" ref="changeCredentialForm">
            <v-text-field
                :label="text.current_password_label"
                v-model="current_password"
                :type="showCurrentPassword ? 'text' : 'password'"
                :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showCurrentPassword = !showCurrentPassword"
                @click="errorMessage = 'temp message'"/>

            <v-text-field
                v-if="change_type == credential_type.PASSWORD"
                :label="text.new_password_label"
                :rules="[rules.required, rules.password]"
                v-model="new_credential"
                :type="showNewPassword ? 'text' : 'password'"
                :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showNewPassword = !showNewPassword"
                @click="errorMessage = 'temp message'"/>
            <v-text-field
                v-else
                :label="new_label"
                :rules="this.change_type == credential_type.EMAIL ? [rules.required, rules.email] : [rules.required, rules.string]"
                v-model="new_credential"
                type="text"
                @click="errorMessage = 'temp message'"></v-text-field>

            <v-text-field
                v-if="change_type == credential_type.PASSWORD"
                :label="text.re_new_password_label"
                :rules="[rules.required, custom_rules.secondPassword]"
                v-model="re_new_credential"
                :type="showReNewPassword ? 'text' : 'password'"
                :append-icon="showReNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showReNewPassword = !showReNewPassword"
                @click="errorMessage = 'temp message'"/>
          </v-form>
          <div :class="errorMessage == 'temp message' ? 'err-msg-hide' : 'err-msg-show'">
            {{ errorMessage }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <button class="common-button change-dialog-button"
              :style="!valid ? 'opacity: 60%; right: 126px' : 'right: 126px'"
              :disabled="!valid"
              @click="change()">
              {{chage_text}}
          </button>
          <button class="common-button change-dialog-button ml-3"
              style="right: 16px;"
              @click="cleanAndClose()">
              {{cancel_text}}
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { ALERT_TYPE, CREDENTIAL_TYPE, MOBILE_BORDER } from './define.js'
import all_text from './ru-text'
import ALL_RULES from './rules'
export default {
  props: {
    change_dialog: {
        Boolean
    },
    change_type: {
        String,
    },
    closeDialog: {
        type: Function
    }
  },
  name: 'ChangeDialog',
  data() {
    return {
      valid: false,
      credential_type: CREDENTIAL_TYPE,
      new_credential: null,
      re_new_credential: null,
      current_password: null,
      showCurrentPassword: false,
      showNewPassword: false,
      showReNewPassword: false,
      errorMessage: 'temp message',
      text: all_text.login,
      chage_text: all_text.change,
      cancel_text: all_text.cancel,
      loading: false,

      rules: ALL_RULES,
      mobile_border: MOBILE_BORDER,

      custom_rules: {
          secondPassword: value => {
              return value == this.new_credential || 'Пароли не совпадают'
          }
      }
    }
  },
  computed: {
      new_label() {
          // example: Новое имя пользователя
          let label = ''
          if (this.change_type == CREDENTIAL_TYPE.USERNAME ||
              this.change_type == CREDENTIAL_TYPE.NAME) {
              label += all_text.new_s
          } else if (this.change_type == CREDENTIAL_TYPE.LAST_NAME) {
              label += all_text.new_w
          } else {
              label += all_text.new_m
          }
          label += ' ' + this.change_type
          return label
      }
  },
  methods: {
      change() {
          let changeMethod =  ''
          let payload = {}
          if (this.change_type == this.credential_type.PASSWORD) {
              changeMethod = 'CHANGE_PASSWORD'
              payload = {'new_password': this.new_credential, 
                          're_new_password': this.re_new_credential}
          } else if (this.change_type == this.credential_type.USERNAME) {
              changeMethod = 'CHANGE_USERNAME'
              payload = {'new_username': this.new_credential,
                          're_new_username': this.new_credential}
          } else if (this.change_type == this.credential_type.EMAIL) {
              changeMethod = 'CHANGE_EMAIL'
              payload = {'new_email': this.new_credential,
                          're_new_email': this.new_credential}
          } else if (this.change_type == this.credential_type.NAME) {
              changeMethod = 'CHANGE_NAME'
              payload = {'new_first_name': this.new_credential}
          } else if (this.change_type == this.credential_type.LAST_NAME) {
              changeMethod = 'CHANGE_NAME'
              payload = {'new_last_name': this.new_credential}
          }
          payload['current_password'] = this.current_password
          this.loading = true
          this.$store.dispatch(changeMethod, payload).then((res) => {
              this.loading = false
              if (res.status == 204 || res.status == 200) {
                  let message = all_text.succsesfull_change
                  if (this.change_type == this.credential_type.EMAIL) {
                      message += '\n' + all_text.email_confirmation
                  }
                  this.$store.commit("RAISE_ALERT", 
                                      {'message': message,
                                      'status': ALERT_TYPE.SUCCESS})
                  this.cleanAndClose()
              }
              
          }).catch((err) => {
              this.loading = false
              this.errorMessage = ''
              Object.entries(err.response.data).forEach(([key, values]) => {
                  if (key == 'new_name') {
                      values.forEach(errMessage => {
                          if (errMessage == 'Wrong name.') {
                              this.errorMessage += this.text.wrong_name
                          }
                      });
                  }
                  if (key == 'new_username') {
                      values.forEach(errMessage => {
                          if (errMessage == 'A user with that username already exists.') {
                              this.errorMessage += this.text.not_unique_username_err
                          }
                      });
                  }
                  if (key == 'current_password') {
                      values.forEach(errMessage => {
                          if (errMessage == 'Invalid password.') {
                              this.errorMessage += this.text.wrong_password
                          }
                      });
                  }
                  if (key == 'new_password') {
                      this.passwordErr = ''
                      values.forEach(errMessage => {
                          if (errMessage == 'The password is too similar to the username.' || 
                              errMessage == 'The password is too similar to the email address.') {
                              this.errorMessage += this.text.password_like_ermail_username
                          }
                      });
                  }
                  if (key == 'new_email') {
                      this.emailErr = ''
                      values.forEach(errMessage => {
                          if (errMessage == 'user with this email address already exists.') {
                              this.errorMessage += this.text.not_unique_email_err
                          }
                      });
                  }
              })
              
          });
      },
    cleanAndClose() {
      this.errorMessage = 'temp message'
      this.$refs.changeCredentialForm.reset()
      this.closeDialog()
    },
  }
}
</script>
<style>
.change-dialog-button {
  position: absolute;
  bottom: 16px;
  padding: 5px 12px;
}
</style>
