import Vue from 'vue'
import VueRouter from 'vue-router'
import MainPage from './components/MainPage.vue'
import Products from './components/Products.vue'
import Product from './components/Product.vue'
import Recipes from './components/Recipes.vue'
import Recipe from './components/Recipe.vue'
import Calculator from './components/Calculator.vue'
import LogIn from './components/LogIn.vue'
// import SingUp from './components/SingUp.vue'
import UserProfile from './components/UserProfile.vue'
import ServerError from './components/ServerError.vue'
import NotFound from './components/NotFound.vue'
import FinishActivation from './components/FinishActivation.vue'
import ResetPassword from './components/ResetPassword.vue'

// rgb(82 122 154)
// rgb(143 161 173 / 39%)
Vue.use(VueRouter)

const routes = [
  { path: '/NotFound', component: NotFound },
  { path: '/ServerError', component: ServerError },
  { path: '/login', component: LogIn },
  // { path: '/singup', component: SingUp },
  { path: '/profile', component: UserProfile },
  { path: '/calculator', component: Calculator },
  { path: '/', component: MainPage },
  { path: '/products', component: Products },
  { path: '/product/:product_type/:id', component: Product },
  { path: '/recipes', component: Recipes },
  { path: '/recipe/:id', component: Recipe },
  { path: '/activate/:uid/:token', component: FinishActivation },
  { path: '/email/confimation/:uid/:token', component: FinishActivation },
  { path: '/password/reset/confirm/:uid/:token', component: ResetPassword },
  { path: '/username/reset/confirm/:uid/:token', component: ResetPassword }
]

// 3. Создаём экземпляр маршрутизатора и передаём маршруты в опции `routes`
// Вы можете передавать и дополнительные опции, но пока не будем усложнять.
 const router = new VueRouter({
  routes // сокращённая запись для `routes: routes`
})

export default router