<template>
  <v-container>
    <TitleOfLists
      :main_title="text.products"
      :updateSearch="updateSearch"
      :createNewTitle="text.product.create_new"
      :onCreateNew="openSaveProductDialog"
      :titleForOwners="text.product.only_my"
      :applyFilters="applyFilters"
      :setCheckbox="setCheckbox"
      :updateSelectedStopProducts="updateSelectedStopProducts"
      :updateSelectedCategories="updateSelectedCategories"
       />

    <CreateUpdateProduct v-if="openNewProductDialog == true" 
            :changeMode="false"
            :closeDialog="onCloseSaveProductDialog" 
            :productItem="{}" 
            :dialog="openNewProductDialog"/>
      
    <template v-if="!$store.getters.LOADING && products && Object.keys(products).length !== 0">
      <template v-for="(productsList, type) in products">
        <span :key="type">
          <span>
            <ProductTitle :title="type" />
          </span>

          <span class="all-card-container">
            <template v-for="(product, name) in productsList">
              <span :key="name + type" class="card-container card-container-size">
                  <span class="products-card-content-container">
                      <span class="card-text" style="justify-self: left;">{{ product.name }}</span>
                      <img
                        style="justify-self: right;"
                        v-if="product.is_owner"
                        src="./icons/user.svg" />
                  </span>
                  <span style="align-self: end;">
                      <StopProducts :info="product" 
                                    :smallMode='true'
                                    style="padding-left: 0px !important;
                                           padding-right: 0px !important;
                                           padding-top: 20px !important;
                                           padding-bottom: 20px !important;"/>
                      <div
                        v-for="(name, label) in component_names"
                        :key="label + product.name"
                        class="products-card-content-container card-text" style="font-size: 15px !important;">
                          <span style="justify-self: left;">{{ name }}</span> 
                          <span style="justify-self: right; padding-right: 10px;">{{ product[label].toFixed(2) }}</span>
                      </div>
                  </span>

                  <button
                    :disabled="$store.getters.LOADING"
                    class="card-product-button common-button"
                    style="align-self: end;"
                    @click="goToProdcuts(type, product.id)">
                    {{ text.show }}
                  </button>
              </span>
            </template>
              </span>
            </span>
          </template>
      </template>
      <template v-else-if="!$store.getters.LOADING && products && Object.keys(products).length === 0">
        <div class="products-not-found">{{ text.product.not_found }}</div>
      </template>
  </v-container>
</template>

<script>
import CreateUpdateProduct from './CreateUpdateProduct.vue'
import StopProducts from './StopProducts'
import ProductTitle from './ProductTitle'
import TitleOfLists from './TitleOfLists'
import {STOP_PRODUCTS_MAP, COMPONENT_NAME, MOBILE_BORDER} from './define'
import all_text from './ru-text'

import './font/Lato/Lato-Regular.ttf'

export default {
    name: 'Products',
    components: {
        CreateUpdateProduct,
        StopProducts,
        ProductTitle,
        TitleOfLists
    },
    data() {
        return {
          loading: false,

          products: null,
          allProducts: {},
          search: '',
          selectedCategories: [],
          allCategories: [],

          selectedStopProducts: [],
          openNewProductDialog: false,
          myProductsOnly: false,

          component_names: COMPONENT_NAME,
          text: all_text,
          mobile_border: MOBILE_BORDER,

          no_products_err_msg: all_text.product.not_found
        }
    },
    methods: {
      updateSearch(new_search) {
        this.search = new_search
        this.applyFilters()
      },
      openSaveProductDialog() {
        this.openNewProductDialog = true;
      },
      onCloseSaveProductDialog(isSave, isSuccsess, newProduct) {
        this.openNewProductDialog = false;
        if (isSave && isSuccsess) {
          this.$router.push('/product/' + newProduct['product_type'] + '/' + newProduct['id']);
        }
      },
      goToProdcuts(type, id){
        this.$router.push('/product/' + type + '/' + id); 
      },
      setCheckbox(myProductsOnly) {
        this.myProductsOnly = myProductsOnly;
      },
      updateSelectedCategories(newSelectedCategories) {
        this.selectedCategories = newSelectedCategories;
      },
      updateSelectedStopProducts(newSelectedStopProducts) {
        this.selectedStopProducts = newSelectedStopProducts;
      },
      applyFilters() {
        let newProducts = {}
        let lowerSearch = this.search.toLowerCase()

        Object.entries(this.allProducts).forEach(([productType, productsItems]) => {
          // filter by categories
          if (this.selectedCategories.length > 0 &&
              this.selectedCategories.indexOf(productType) == -1) {
            return;
          }

          let temp = []
          Object.entries(productsItems).forEach(([, info]) => {
            let add = this.selectedStopProducts.length == 0 ||
                      this.selectedStopProducts.every(stopProduct => 
                                                      info[STOP_PRODUCTS_MAP[stopProduct]]);
                  
            add &= info.name.toLowerCase().indexOf(lowerSearch) != -1;
            add &= (this.myProductsOnly && info.is_owner) || !this.myProductsOnly
            if (add) {
              temp.push(info)
            }
          });

          if (temp.length > 0) {
            newProducts[productType] = temp
          }
        })
        this.products = newProducts;
      }
    },
    created() {
      this.allProducts = this.$store.getters.PRODUCTS
      this.products = this.$store.getters.PRODUCTS

      if (this.$store.getters.PRODUCTS == null) {
        this.$store.commit("SET_LOADING", true)
        this.$store.dispatch('GET_PRODUCTS').then((res) => {
          this.$store.commit("SET_LOADING", false)
            if (res.status == 200) {
                this.allProducts = this.$store.getters.PRODUCTS
                this.products = this.$store.getters.PRODUCTS
            } else {
              this.no_products_err_msg = this.text.common_error_message
            }
        }).catch(() => {
          this.$store.commit("SET_LOADING", false)
          this.no_products_err_msg = this.text.no_connection_error_message
        });
      }
    
      if (this.$store.getters.PRODUCT_TYPES == null) {
        this.$store.commit("SET_LOADING", true)
        this.$store.dispatch('GET_PRODUCT_TYPES').then((res) => {
          this.$store.commit("SET_LOADING", false)
          if (res.status == 200) {
              this.allCategories = res.data
          } else {
            this.no_products_err_msg = this.text.common_error_message
          }
        }).catch(() => {
          this.$store.commit("SET_LOADING", false)
          this.no_products_err_msg = this.text.no_connection_error_message
        });
      } else {
        this.allCategories = this.$store.getters.PRODUCT_TYPES
      }
    },
}
</script>

<style lang="scss">
@import './common.scss';

.all-card-container {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
}

.card-container {
  display: grid;
  border-radius: 10px;
  background-color: var(--v-main_background-base);
  box-shadow: 0px 0px 14px 3px #8d8585;
}

.card-container-size {
  margin: 30px;
  padding: 20px;
  width: 270px;
}

.products-card-content-container {
  display: grid;
  grid-template-columns: 85% 15%;
}

.card-text {
  color: var(--v-green-base);
  font-size: 20px;
  font-family: 'Lato-Regular';
}

.card-product-button {
  margin-top: 35px;
  width: 120px;
  height: 35px;
  font-size: 12px;
}

.products-not-found {
  font-weight: bold;
  font-size: 30px;
  color: var(--v-green-base) !important;
  margin-top: 20px;
  margin-top: 40px;
  text-align: center;
}

@media (max-width: 950px) {

  .card-container-size {
    margin: 30px;
    width: 270px;
  }

  .products-not-found {
    font-weight: bold;
    font-size: 20px;
    color: var(--v-green-base) !important;
    margin-top: 20px;
    margin-top: 40px;
    text-align: center;
  }

}
</style>
